import React, { useEffect, useRef } from 'react';
import LoggingService from '@kemtai/logger';
import { useLocation } from 'react-router-dom';
import { useUser } from 'libs/auth';
import { IUser } from 'features/auth';
import { useClinic } from 'features/clinic';
import { ENVIRONMENT } from 'config';


type LogOptions = {
  event: string;
} & Record<string, any>;

type LoggerContextValue = {
  log: (options: LogOptions) => void;
  identify: (user: IUser) => void;
};

export const LoggerContext = React.createContext<LoggerContextValue>({} as LoggerContextValue);

type LoggerProviderProps = {
  app: string;
  apiUrl?: string;
};

export const LoggerProvider: React.FC<LoggerProviderProps> = ({ app, apiUrl, children }) => {

  useEffect(() => {
    if (ENVIRONMENT) {
      LoggingService.init({
        app: app,
        url: apiUrl ?? "https://api.kemtai.com/log/"
      });
    }
  }, []);

  const log = ({ event, ...data }: LogOptions) => {
    LoggingService.event(event, data);
    /*
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...data
      }
    });
    */
  }

  const identify = (user: IUser) => {
    LoggingService.setContext("user_email", user.id);

    log({
     event: "user",
     data: {
       id: user.id,
       email: user.email
     }
    });
    /*
    TagManager.dataLayer({
			dataLayer: {
				event: "identify",
				user_id: user.id
			},
		});

    TagManager.dataLayer({
			dataLayer: {
				event: "set_profile",
        email: user.email,
				first_name: user.first_name,
        last_name: user.last_name,
			},
		});
    
    TagManager.dataLayer({
			dataLayer: {
				event: "add_group",
				group_key: "application",
        group_id: "care-manage"
			},
		});
    */
  }

  const user = useUser({
    onSuccess: (user) => {
      if (user) {
        identify(user);
      }
    }
  });

  const lastLocation = useRef(window.location.href);
  const location = useLocation();

  useEffect(() => {
    if (lastLocation.current !== window.location.href) {
      log({
        event: "URLChange",
        data: {
          oldURL: lastLocation.current,
          newURL: window.location.href,
        }
      });

      lastLocation.current = window.location.href;
    }
  }, [location]);

	return (
		<LoggerContext.Provider value={{ log, identify }}>
		  {children}
		</LoggerContext.Provider>
	);
};
