type ITimeout = {
	name: string;
	timeoutId: NodeJS.Timeout;
	delay: number;
	callback: () => void;
	startTime: number;
	remaining: number;
};

class Timeout {
	timeouts: Array<ITimeout> = [];
	killed = false;

	set(callback: () => void, delay: number, name = '') {
		if (this.killed) {
			return;
		}

		const timeoutId = setTimeout(callback, delay);
		this.timeouts.push({
			name: name,
			timeoutId: timeoutId,
			delay: delay,
			callback: callback,
			startTime: Date.now(),
			remaining: delay,
		});
	}

	pause(name: string) {
		const timeout = this.timeouts.find((timeout: ITimeout) => timeout.name === name);

		if (timeout) {
			clearTimeout(timeout.timeoutId);
			timeout.remaining -= Date.now() - timeout.startTime;

			if (timeout.remaining <= 0) {
				this.timeouts = this.timeouts.filter((t: ITimeout) => t.name !== name);
			}
		}
	}

	pauseAll() {
		const finishedTimeouts: Array<number> = [];

		this.timeouts.forEach((timeout: ITimeout, index: number) => {
			clearTimeout(timeout.timeoutId);
			timeout.remaining -= Date.now() - timeout.startTime;

			if (timeout.remaining <= 0) {
				finishedTimeouts.push(index);
			}
		});

		this.timeouts = this.timeouts.filter(
			(timeout: ITimeout, index: number) => !finishedTimeouts.includes(index)
		);
	}

	resumeAll() {
		this.timeouts.forEach((timeout: ITimeout) => {
			timeout.timeoutId = setTimeout(timeout.callback, timeout.remaining);
			timeout.startTime = Date.now();
		});
	}

	resume(name: string) {
		const timeout = this.timeouts.find((timeout: ITimeout) => timeout.name === name);

		if (timeout) {
			timeout.timeoutId = setTimeout(timeout.callback, timeout.remaining);
			timeout.startTime = Date.now();
		}
	}

	clear(name: string) {
		let timeoutIndex = -1;

		this.timeouts.forEach((timeout: ITimeout, index: number) => {
			if (timeout.name === name) {
				clearTimeout(timeout.timeoutId);
				timeoutIndex = index;
			}
		});

		if (timeoutIndex >= 0) {
			this.timeouts.splice(timeoutIndex, 1);
		}
	}

	clearAll() {
		this.timeouts.forEach((timeout: ITimeout) => {
			clearTimeout(timeout.timeoutId);
		});

		this.timeouts = [];
	}

	kill() {
		this.killed = true;
		this.clearAll();
	}
}

export { Timeout };
