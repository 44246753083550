import { AppearanceOptions } from '@kemtai/web-sdk';
import { createTheme, darken, lighten, Palette, PaletteColor, ThemeOptions } from '@mui/material/styles';
import { themeComponentsMixin } from 'theme';


const defaultColors: AppearanceOptions['colors'] = {
  primary25: "#F7FAFA",
  primary50: "#DDF6F3",
  primary100: "#B9ECE5",
  primary200: "#1CC3AC",
  primary300: "#19B09B",
  primary400: "#169C8A",
  primary500: "#159281",
  primary600: "#117567",
  primary700: "#045F53",
  primary800: "#0A443C",
  primaryContrastText: "#fff",
  secondaryButton: "#000",
  secondary25: "#F2F5F7",
  secondary50: "#E8EDF2",
  secondary100: "#DAE2EA",
  secondary200: "#B7C0C6",
  secondary400: "#889098",
  secondary500: "#6E767D",
  secondary600: "#117567",
  secondary700: "#4B4B4B",
  text100: "#939BAB",
  text200: "#737B88",
  text300: "#2E3139",
};

type ComposeThemeOptions = {
  direction?: "ltr"|"rtl";
  appearance?: AppearanceOptions;
};

export const composeTheme = ({ direction, appearance }: ComposeThemeOptions) => {
  const { typography, breakpoints } = createTheme({
    typography: { 
      fontSize: appearance?.baseFontSize ?? 14,
    },
  });

  const colors = {
    ...defaultColors,
    ...appearance?.colors
  };

  return createTheme({
    ...themeComponentsMixin,
    palette: {
      mode: "light",
      background: {
        50: "#E8EDF2",
        default: appearance?.colors?.background ?? "#fff",
        paper: appearance?.colors?.paper ?? "#fff",
      },
      primary: {
        25: colors.primary25,
        50: colors.primary50,
        100: colors.primary100,
        200: colors.primary200,
        300: colors.primary300,
        400: colors.primary400,
        500: colors.primary500,
        600: colors.primary600,
        700: colors.primary700,
        800: colors.primary800,
        light: colors.primary100,
        main: colors.primary200,
        dark: colors.primary300,
        contrastText: colors.primaryContrastText,
      },
      secondary: {
        button: colors.secondaryButton,
        25: colors.secondary25,
        50: colors.secondary50,
        100: colors.secondary100,
        200: colors.secondary200,
        400: colors.secondary400,
        500: colors.secondary500,
        600: colors.secondary600,
        700: colors.secondary700,
        main: colors.secondary400,
      },
      success: {
        main: "#1CC3AC",
        light: "#E9FBF8",
      },
      warning: {
        light: "#FCB036",
        main: "#FF8A00",
        300: "#FCB036",
        400: "#FF8A00",
      },
      info: {
        main: "#47A1F5",
      },
      error: {
        25: "#FBF2F1",
        50: "#F5DFDC",
        200: "#F8C0BA",
        300: "#FF634E",
        400: "#F34129",
        light: "#F8C0BA",
        main: "#FF634E",
        dark: "#F34129",
        contrastText: "#fff"
      },
      text: {
        100: colors.text100,
        200: colors.text200,
        300: colors.text300,
        primary: colors.text300,
        secondary: colors.text200,
      },
      custom: {
        paper2: "#E6ECF1",
        paper3: "#F7F8F7",
        text: "#2E3139",
        purple: "#6D53CC",
        blue: "#47A1F5",
      }
    },
    typography: {
      fontSize: appearance?.baseFontSize ?? 14,
      fontFamily: [
        "Poppins", 
        "Arial"
      ].join(","),
      h1: {
        fontWeight: 700,
        fontSize: typography.pxToRem(44),
        lineHeight: typography.pxToRem(66),
      },
      h2: {
        fontWeight: 700,
        fontSize: typography.pxToRem(24),
        lineHeight: typography.pxToRem(36),
      },
      h3: {
        fontWeight: 700,
        fontSize: typography.pxToRem(20),
        lineHeight: typography.pxToRem(30),
      },
      h4: {
        fontWeight: 700,
        fontSize: typography.pxToRem(18),
        lineHeight: typography.pxToRem(27),
      },
      h5: {
        fontWeight: 700,
        fontSize: typography.pxToRem(16),
        lineHeight: typography.pxToRem(24),
      },
      h6: {
        fontWeight: 700,
        fontSize: typography.pxToRem(14),
        lineHeight: typography.pxToRem(21),
      },
      h7: {
        fontWeight: 700,
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(18),
      },
      subtitle1: {
        fontWeight: 600,
        fontSize: typography.pxToRem(18),
        lineHeight: typography.pxToRem(20),
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: typography.pxToRem(16),
        lineHeight: typography.pxToRem(24),
      },
      subtitle3: {
        fontWeight: 600,
        fontSize: typography.pxToRem(14),
        lineHeight: typography.pxToRem(21),
      },
      subtitle4: {
        fontWeight: 600,
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(18),
      },
      body1: {
        fontWeight: 500,
        fontSize: typography.pxToRem(18),
        lineHeight: typography.pxToRem(27),
      },
      body2: {
        fontWeight: 500,
        fontSize: typography.pxToRem(16),
        lineHeight: typography.pxToRem(24),
      },
      body3: {
        fontWeight: 500,
        fontSize: typography.pxToRem(14),
        lineHeight: typography.pxToRem(21),
      },
      body4: {
        fontWeight: 500,
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(18),
      },
      button1: {
        fontWeight: 600,
        fontSize: typography.pxToRem(16),
        lineHeight: typography.pxToRem(24),
      },
      button2: {
        fontWeight: 600,
        fontSize: typography.pxToRem(14),
        lineHeight: typography.pxToRem(21),
      },
      caption1: {
        fontWeight: 400,
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(18),
      },
      caption2: {
        fontWeight: 500,
        fontSize: typography.pxToRem(10),
        lineHeight: typography.pxToRem(15),
      },
    },
  } as ThemeOptions);
}