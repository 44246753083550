export interface IPolicyRule {
	action: string;
	tenant: string;
	resource_id: string|null;
	resource_type: string;
	role: string;
};


export class Authorizer {

  adjacencyList: { [v: string]: Set<string> } = {};
  policy: IPolicyRule[] = [];

  constructor({ policy }: { policy: IPolicyRule[] }) {
		this.policy = policy;
    this.loadPolicy();
  }

  async loadPolicy() {
    this.policy.forEach((policyRule) => {
      const v1 = `${policyRule.role}|${policyRule.tenant}`;
      const v2 = policyRule.resource_id
                  ? policyRule.resource_type === "authorization_group"
											?	`${policyRule.resource_id}|${policyRule.action}`
											: `${policyRule.resource_type}:${policyRule.resource_id}|${policyRule.action}`
                  : `${policyRule.resource_type}|${policyRule.action}`;

      if (this.adjacencyList[v1] === undefined) {
        this.adjacencyList[v1] = new Set<string>;
      }

      this.adjacencyList[v1].add(v2);
    });
  }

  authorize(roles: string[], resource: string, action: string) {
    for (const role of roles) {
      const source = `${role}|*`
      const destination = `${resource}|${action}`;
      const isPermitted = this.isReachable(source, destination);

      if (isPermitted) {
        return true;
      }
    }

    return false;
  }

  private keyMatch(key1: string, key2: string) {
    const i = key2.indexOf("*");

    if (i === -1) {
      return key1 === key2;
    }

    if (key1.length > i) {
      return key1.slice(0, i) === key2.slice(0, i);
    }

    return key1 === key2.slice(0, i);
  }

  private isReachable(source: string, destination: string) {
    const visited = new Set<string>();
    const queue = [];

    queue.push(source);

    visited.add(source);

    while (queue.length > 0) {
      const current = queue.pop()!;

      if (this.keyMatch(destination, current) || this.keyMatch(current, destination)) {
        return true;
      }

      for (const [vertice, neighbours] of Object.entries(this.adjacencyList)) {
        if (this.keyMatch(current, vertice) || this.keyMatch(vertice, current)) {
          neighbours.forEach((neighbour) => {
            if (!visited.has(neighbour)) {
              queue.push(neighbour);
              visited.add(neighbour);
            }
          });
        }
      }
    }

    return false;
  }

}
