function decode(s:string) {
    const nn = JSON.parse(s) as number[];
    return nn.map((x, i) => String.fromCharCode(x + 90)).join('')
}
  

export const API_URL = process.env.REACT_APP_API_URL as string;
export const VERSION = process.env.REACT_APP_VERSION as string;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;
export const PROJECT_KEY = process.env.REACT_APP_PROJECT_KEY as string;
export const ENVIRONMENT_KEY = process.env.REACT_APP_ENVIRONMENT_KEY as string;
export const AWS_ACCESS_KEY = decode(process.env.REACT_APP_AWS_ACCESS_KEY as string);
export const AWS_SECRET = decode(process.env.REACT_APP_AWS_SECRET as string);
export const AWS_REGION = process.env.REACT_APP_AWS_REGION as string;
export const AWS_VIDEOS_BUCKET_NAME = process.env.REACT_APP_AWS_VIDEOS_BUCKET_NAME as string;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;
export const IS_EMBEDDABLE = window.location.pathname.startsWith("/embeddables/");
export const IS_LEGACY_EMBEDDABLE = window.location.pathname.startsWith("/embed/");
export const EMBED_URL = process.env.REACT_APP_EMBED_URL as string;
