import { axios } from 'libs/axios';
import { useMutation, MutationConfig, queryClient } from 'libs/react-query';
import { useNotificationStore } from 'stores';

import { IStaff } from '../types';

export type UpdateCurrentStaffDTO = {
	data: Partial<IStaff>;
};

export const updateCurrentStaff = ({ data }: UpdateCurrentStaffDTO): Promise<IStaff> => {
	return axios.patch(`/care/current-staff/`, data);
};

type UseUpdateCurrentStaffOptions = {
	config?: MutationConfig<typeof updateCurrentStaff>;
};

export const useUpdateCurrentStaff = ({ config }: UseUpdateCurrentStaffOptions = {}) => {
	const { addNotification } = useNotificationStore();

	return useMutation({
		mutationFn: updateCurrentStaff,
		onMutate: async (variables) => {
			await queryClient.cancelQueries(['current-staff']);

			const previous = queryClient.getQueryData(['current-staff']);

			// queryClient.setQueryData<IStaff>(['current-staff'], old => ({ ...(old ?? {}), ...variables.data }));

			return {
				previous,
			};
		},
		onError: (_, __, context: any) => {
			queryClient.setQueryData<IStaff>(['current-staff'], context.previous);
		},
		onSuccess: (result, variables, context: any) => {
			queryClient.setQueryData<IStaff>(['current-staff'], result);
		},
		onSettled: () => {
			queryClient.invalidateQueries(['current-staff']);
		},
		...config,
	});
};
