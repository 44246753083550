import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useTheme, useMediaQuery, Box } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { VERSION } from "config";
import ManufacturerIcon from 'assets/icons/regulation/manufacturer.png';
import PNIcon from 'assets/icons/regulation/pn.png';
import MDIcon from 'assets/icons/regulation/md.png';
import RXIcon from 'assets/icons/regulation/rx.png';
import InstructionIcon from 'assets/icons/regulation/instruction.png';
import { FormattedMessage } from "react-intl";


type AboutUsDialogProps = {
  open: boolean;
	onClose: () => void;
};

export const AboutUsDialog: React.FC<AboutUsDialogProps> = ({ open, onClose }) => {

  const theme = useTheme();

  return (
    <Dialog 
      maxWidth="sm" 
      open={open} 
    >
      <DialogTitle>
        <FormattedMessage 
          defaultMessage="About us" id="wGhqrf"
        />

        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
          >
            <img 
              src={ManufacturerIcon}
              alt=""
              width="30px"
            />

            <Typography>
              <Typography
                variant="h6"
              >
                <FormattedMessage 
                  defaultMessage="Kemtai Ltd." id="QxzT9d"
                />
              </Typography>

              Em Hamoshavot 94, Petach Tikva, Israel
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            gap={1}
            mt={1}
          >
            <img 
              src={PNIcon}
              alt=""
              width="30px"
            />

            <Typography
              variant="h6"
            >
              KM-001
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={4}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={0.5}
            >
              <img
                src={RXIcon}
                alt=""
                width="50px"
              />

              <img
                src={MDIcon}
                alt=""
                width="30px"
              />

              <img
                src={InstructionIcon}
                alt=""
                width="30px"
              />
            </Box>

            <Typography>
              <FormattedMessage 
                defaultMessage="Software Version" id="v5SNJa"
              />: v{VERSION.split(".").map(part => `${[...Array(3 - part.length)].fill(0).join("")}${part}`).join(".")}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );

}
