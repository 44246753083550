import { useCallback, useState } from 'react';

export const useDisclosure = (initial = false) => {
  const [isOpen, setIsOpen] = useState(initial);
  const [anchor, setAnchor] = useState<null|HTMLElement>(null);

  const open = useCallback((event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      setAnchor(event.currentTarget);
    }

    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    setAnchor(null);
  }, []);

  const toggle = useCallback((event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      setAnchor(current => current ? null : event.currentTarget);
    }

    setIsOpen(current => !current);
  }, []);

  return { isOpen, open, close, toggle, anchor };
};