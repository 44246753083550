import { useAuthorization } from "libs/auth";
import { SidebarMenuItem } from "./types";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { UnreadMessagesBadge } from "features/chat";
import SettingsIcon from '@mui/icons-material/Settings';
import { MetricsIcon } from "assets/icons";
import { organizationPermissionsEnum, useClinic } from "features/clinic";
import ImageIcon from '@mui/icons-material/Image';
import AppsIcon from '@mui/icons-material/Apps';
import { useIntl } from "react-intl";


export const useMenuItems = () => {
  const { checkAccess } = useAuthorization();
  const { clinic } = useClinic();
  const { formatMessage } = useIntl();

  const menuItems: SidebarMenuItem[] = [
    { 
      label: formatMessage({ defaultMessage: "Home", id: 'ejEGdx' }), 
      path: "/",
      icon: <DashboardIcon 
              fontSize="inherit"
            />
    },
    { 
      label: formatMessage({ defaultMessage: "Patients", id: '1eiSDT' }), 
      path: "/users",
      icon: <PeopleIcon 
              fontSize="inherit"
            />,
    },
    {
      label: formatMessage({ defaultMessage: "Inbox", id: 'VCMKbt' }),
      path: "/inbox",
      icon: (
        <UnreadMessagesBadge>
          <QuestionAnswerIcon 
            fontSize="inherit"
          />
        </UnreadMessagesBadge>
      ),
    },
    {
      label: formatMessage({ defaultMessage: "Exercises", id: 'lJX87E' }),
      icon: <VideoLibraryIcon 
            fontSize="inherit"
          />,
      children: [
        {
          label: formatMessage({ defaultMessage: "Rehab", id: 'BbxJNE' }),
          path: "/exercises/rehab"
        },
        {
          label: formatMessage({ defaultMessage: "Fitness", id: 'J5xdEq' }),
          path: "/exercises/fitness"
        },
        {
          label: formatMessage({ defaultMessage: "Range of Motion", id: 'rnwyQK' }),
          path: "/exercises/rom",
          isPermitted: !!clinic?.permissions.includes(organizationPermissionsEnum.enum.manage_rom_assessments)
        },
        {
          label: formatMessage({ defaultMessage: "Functional", id: 'VDUW3m' }),
          path: "/exercises/functional",
          isPermitted: !!clinic?.permissions.includes(organizationPermissionsEnum.enum.manage_functional_assessments)
        }
      ]
    },
    {
      label: formatMessage({ defaultMessage: "Templates library", id: '+dZxen' }),
      icon: <AppsIcon 
        fontSize="inherit"
      />,
      children: [
        {
          label: formatMessage({ defaultMessage: "Exercise protocols", id: 'mb/O8Y' }),
          path: "/exercise-protocols",
          isPermitted: !!clinic?.permissions.includes(organizationPermissionsEnum.enum.manage_exercise_protocols)
        },
        {
          label: formatMessage({ defaultMessage: "ROM assessments", id: '2c4VOe' }),
          path: "/rom-assessments",
          isPermitted: !!clinic?.permissions.includes(organizationPermissionsEnum.enum.manage_rom_assessments)
        },
        {
          label: formatMessage({ defaultMessage: "Functional assessments", id: 'h+4oqD' }),
          path: "/functional-assessments",
          isPermitted: !!clinic?.permissions.includes(organizationPermissionsEnum.enum.manage_functional_assessments)
        },
        {
          label: formatMessage({ defaultMessage: "STS assessments", id: 'vG+31S' }),
          path: "/sts-assessments",
          isPermitted: !!clinic?.permissions.includes(organizationPermissionsEnum.enum.manage_sts_assessments)
        },
        {
          label: formatMessage({ defaultMessage: "Forms", id: 'yGsZm0' }),
          path: "/forms",
          isPermitted: !!clinic?.permissions.includes(organizationPermissionsEnum.enum.manage_forms)
        },
        {
          label: formatMessage({ defaultMessage: "Education", id: 'jtygmI' }),
          path: "/educational-contents",
          isPermitted: !!clinic?.permissions.includes(organizationPermissionsEnum.enum.manage_educational_contents)
        },
        {
          label: formatMessage({ defaultMessage: "Tasks", id: 'yhU1et' }),
          path: "/tasks",
          isPermitted: !!clinic?.permissions.includes(organizationPermissionsEnum.enum.manage_tasks)
        },
        {
          label: formatMessage({ defaultMessage: "Care plans", id: 'y10hgk' }),
          path: "/care-plans",
        },
      ]
    },
    {
      label: formatMessage({ defaultMessage: "Metrics", id: 'HNBpJ4' }),
      path: "/metrics",
      icon: <MetricsIcon 
              fontSize="inherit"
            />,
      isPermitted: !!clinic?.permissions.includes(organizationPermissionsEnum.enum.manage_metrics)
    },
    {
      label: formatMessage({ defaultMessage: "Assets", id: 'd1uESJ' }),
      path: "/assets",
      icon: <ImageIcon 
        fontSize="inherit"
      />,
    },
    { 
      label: formatMessage({ defaultMessage: "Organization", id: 'K56Dim' }), 
      icon: <SettingsIcon 
            fontSize="inherit"
          />,
      isPermitted: checkAccess({ resource: "staff", action: "list" }),
      children: [
        {
          label: formatMessage({ defaultMessage: "Preferences", id: 'PCSt5T' }),
          path: "/clinic/preferences",
        },
        {
          label: formatMessage({ defaultMessage: "Staff members", id: 'pOlMxf' }),
          path: "/staff-members",
        },
      ]
    },
  ];

  return menuItems;
}