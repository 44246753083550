import { Embeddable } from '@kemtai/web-sdk';


export type TaskOptions = {
  taskId: string;
};

export type TaskEvents = {
  close: undefined;
  done: undefined;
};

export class Task extends Embeddable<TaskOptions, TaskEvents> {

  runType = "care";

  name = "task";

  handleEmbeddableMessage = (message: MessageEvent) => {
    if (message.data.type === "close") {
      this.emit("close", undefined);
    } else if (message.data.type === "done") {
      this.emit("done", undefined);
    }
  }

}
