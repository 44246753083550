import { createTheme, ThemeProvider as MuiThemeProvider, darken, lighten, Palette, PaletteColor, ThemeOptions } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import GlobalStyles from './globalStyles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { useEffect } from 'react';
import { useLocale } from 'hooks/useLocale';


const fontSize = 16;
const pxToRem = (value: number) => `${value / fontSize}rem`;

export const themeComponentsMixin: ThemeOptions = {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          background: '#fff',
          borderBottomLeftRadius: '24px',
          borderBottomRightRadius: '24px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 60,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "3px"
        },
        flexContainer: {
          gap: "14px"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: "16px 8px 4px 8px",
          minHeight: "53px",
          textTransform: "none",
          ...theme.typography.subtitle3,
          "& .MuiSvgIcon-root": {
            fontSize: pxToRem(18),
          },
          "&.Mui-selected": {
            color: theme.palette.common.black,
          }
        }),
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text['100'],
        })
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.caption2
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "8px",
          color: theme.palette.text['300'],
          backgroundColor: theme.palette.common.white,
          ...theme.typography.body3,
          "&.Mui-focused": {
            color: theme.palette.text['300']
          },
          "&.Mui-disabled": {
            opacity: 0.5
          },
        }),
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.secondary['200']
        }),
      }
    },
    MuiInputBase: {
      styleOverrides: {
        adornedStart: {
          "& .MuiSvgIcon-root": {
            fontSize: "24px"
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          minHeight: ownerState.size === "small" ? "32px" : "37px",
          borderRadius: "8px",
        }),
        select: ({ ownerState, theme }) => ({
          padding: "0 12px 0 12px",
          border: 0,
          ...theme.typography.body3
        }),
        icon: ({ theme }) => ({
          color: theme.palette.secondary['400']
        }),
      }
    },
    MuiFormControl: {
      defaultProps: {
        size: "small"
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: '12px'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        color: "secondary"
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.size === "small" ? theme.typography.button2 : theme.typography.button1),
          borderRadius: "50px",
          boxShadow: "none",
          textTransform: 'none',
          height: {
            small: "32px",
            medium: "40px",
            large: "48px",
          }[ownerState.size ?? "medium"],
          padding: {
            small: "8px 18px 8px 18px",
            medium: "8px 22px 8px 22px",
            large: "12px 24px 12px 24px",
          }[ownerState.size ?? "medium"],
          ...(ownerState.variant === "text" && ownerState.color === "secondary" && {
            color: theme.palette.secondary.button,
          }),
          ...(ownerState.variant === "contained" && ownerState.color === "secondary" && {
            backgroundColor: theme.palette.secondary.button,
          }),
          ...(ownerState.variant === "outlined" && ownerState.color === "secondary" && {
            borderColor: theme.palette.secondary.button,
            color: theme.palette.secondary.button,
          }),
          ...(ownerState.color === "error" && {
            backgroundColor: theme.palette.error['25'],
            borderColor: theme.palette.error['300'],
            borderStyle: "solid",
            borderWidth: 1,
            color: theme.palette.error['400']
          }),
          "&:hover": {
            boxShadow: "none",
            ...(ownerState.variant === "outlined" && ownerState.color === "secondary" && {
              borderColor: theme.palette.secondary.button,
              color: theme.palette.secondary.button,
            }),
            ...(ownerState.variant === "contained" && ownerState.color === "secondary" && {
              backgroundColor: theme.palette.secondary['800'],
            }),
            ...(ownerState.color === "error" && {
              backgroundColor: theme.palette.error['200'],
            }),
          },
          "&.Mui-disabled": {
            ...(ownerState.color === "primary" && {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
            }),
            ...(ownerState.variant === "contained" && ownerState.color === "secondary" && {
              backgroundColor: theme.palette.secondary['200'],
              color: theme.palette.secondary.contrastText,
            }),
            ...(ownerState.color === "error" && {
              backgroundColor: "transparent",
              borderColor: theme.palette.error['200'],
              color: theme.palette.error['200'],
            }),
          },
        }),
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px'
        },
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.subtitle3
        })
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          ...theme.typography.body3
        })
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-selected": {
            backgroundColor: theme.palette.secondary['25']
          },
          "&:hover": {
            backgroundColor: theme.palette.secondary['25']
          }
        })
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 0px 6px 0px #00000040",
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.body4,
          gap: "8px",
          "& .MuiListItemIcon-root": {
            minWidth: "24px",
          }
        }),
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: ({ theme }) => ({
          ...theme.typography.body4
        }),
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fontSize: "16px"
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiTableCell-root": {
            ...theme.typography.subtitle4,
            color: "#667085",
          }
        })
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.secondary['50'],
          ...theme.typography.body3,
          fontWeight: 400,
          color: "#667085",
          padding: "13px 24px",
        })
      }
    }
  },
};

export const getTheme = (direction: "rtl"|"ltr" = "ltr") => createTheme({
    direction,
    palette: {
    mode: "light",
    background: {
      50: "#E8EDF2",
      default: "#F2F5F7",
      paper: "#fff",
    },
    primary: {
      25: "#F7FAFA",
      50: "#DDF6F3",
      100: "#B9ECE5",
      200: "#1CC3AC",
      300: "#19B09B",
      400: "#169C8A",
      500: "#159281",
      600: "#117567",
      700: "#045F53",
      800: "#0A443C",
      light: "#B9ECE5",
      main: "#1CC3AC",
      dark: "#19B09B",
      contrastText: "#fff",
    },
    secondary: {
      25: "#F2F5F7",
      50: "#E8EDF2",
      100: "#DAE2EA",
      200: "#B7C0C6",
      400: "#889098",
      500: "#6E767D",
      700: "#4B4B4B",
      button: "#000",
      main: "#889098",
    },
    success: {
      main: "#1CC3AC",
      light: "#E9FBF8",
    },
    warning: {
      light: "#FCB036",
      main: "#FF8A00",
      300: "#FCB036",
      400: "#FF8A00",
    },
    info: {
      main: "#47A1F5",
    },
    error: {
      25: "#FBF2F1",
      50: "#F5DFDC",
      200: "#F8C0BA",
      300: "#FF634E",
      400: "#F34129",
      light: "#F8C0BA",
      main: "#FF634E",
      dark: "#F34129",
      contrastText: "#fff"
    },
    text: {
      100: "#939BAB",
      200: "#737B88",
      300: "#2E3139",
      primary: "#2E3139",
      secondary: "#737B88",
    },
    custom: {
      paper2: "#E6ECF1",
      paper3: "#F7F8F7",
      text: "#2E3139",
      purple: "#6D53CC",
      blue: "#47A1F5",
    }
  },
  ...themeComponentsMixin,
  typography: {
    fontSize: fontSize,
    fontFamily: [
      "Poppins", 
      "Arial"
    ].join(","),
    h1: {
      fontWeight: 700,
      fontSize: pxToRem(44),
      lineHeight: pxToRem(66),
    },
    h2: {
      fontWeight: 700,
      fontSize: pxToRem(24),
      lineHeight: pxToRem(36),
    },
    h3: {
      fontWeight: 700,
      fontSize: pxToRem(20),
      lineHeight: pxToRem(30),
    },
    h4: {
      fontWeight: 700,
      fontSize: pxToRem(18),
      lineHeight: pxToRem(27),
    },
    h5: {
      fontWeight: 700,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
    h6: {
      fontWeight: 700,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(21),
    },
    h7: {
      fontWeight: 700,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(18),
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: pxToRem(18),
      lineHeight: pxToRem(20),
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
    subtitle3: {
      fontWeight: 600,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(21),
    },
    subtitle4: {
      fontWeight: 600,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(18),
    },
    body1: {
      fontWeight: 500,
      fontSize: pxToRem(18),
      lineHeight: pxToRem(27),
    },
    body2: {
      fontWeight: 500,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
    body3: {
      fontWeight: 500,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(21),
    },
    body4: {
      fontWeight: 500,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(18),
    },
    button1: {
      fontWeight: 600,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
    button2: {
      fontWeight: 600,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(21),
    },
    caption1: {
      fontWeight: 400,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(18),
    },
    caption2: {
      fontWeight: 500,
      fontSize: pxToRem(10),
      lineHeight: pxToRem(15),
    },
  },
});


export const ThemeProvider: React.FC = ({ children }) => {

  const { direction } = useLocale();
  
  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  if (direction === "rtl") {
    const cacheRtl = createCache({
      key: 'muirtl',
      stylisPlugins: [prefixer, rtlPlugin],
    });

    return (
      <CacheProvider value={cacheRtl}>
        <MuiThemeProvider theme={getTheme("rtl")}>
          <CssBaseline />
          <GlobalStyles />

          {children}
        </MuiThemeProvider>
      </CacheProvider>
    );
  } else {
    return (
      <MuiThemeProvider theme={getTheme()}>
        <CssBaseline />
        <GlobalStyles />

        {children}
      </MuiThemeProvider>
    );
  }

}
