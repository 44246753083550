import { GlobalStyles as MUIGlobalStyles, darken, lighten, useTheme } from '@mui/material';
import React from 'react';

const GlobalStyles = () => {
	const theme = useTheme();

	return (
		<MUIGlobalStyles
			styles={{
				html: {
					height: '100%'
				},
				body: {
					margin: 0,
					padding: 0,
					lineHeight: 1,
					height: '100%',
					touchAction: 'auto',
					userSelect: 'auto',
				},
				'#root': {
					height: '100%'
				},
				a: {
					textDecoration: 'none',
					color: 'inherit',
				},
				
				'*::-webkit-scrollbar': {
					width: '10px',
					[theme.breakpoints.down("md")]: {
						width: '4px'
					}
				},
				'*::-webkit-scrollbar-track': {
					// backgroundColor: theme.palette.mode === "dark" ? darken(theme.palette.custom!.inverted, 0.9) : lighten(theme.palette.custom!.inverted, 0.9),
					borderRadius: '10px',
				},
				'*::-webkit-scrollbar-thumb': {
					// backgroundColor: theme.palette.mode === "dark" ? darken(theme.palette.custom!.inverted, 0.3) : lighten(theme.palette.custom!.inverted, 0.3),
					borderRadius: '10px',
				},
				'*::-webkit-scrollbar-thumb:hover': {
					// backgroundColor: theme.palette.custom!.inverted,
				}
			}}
		/>
	);
};

export default GlobalStyles;
