import { IStaff } from '../types';
import { axios } from 'libs/axios';
import { useQuery, QueryConfig } from 'libs/react-query';


export const getCurrentStaff = (): Promise<IStaff> => {
  return axios.get(`care/current-staff/`);
};

type UseCurrentStaffOptions = {
  config?: QueryConfig<typeof getCurrentStaff>;
};

export const useCurrentStaff = ({ config }: UseCurrentStaffOptions = {}) => {
  return useQuery<IStaff>({
    queryKey: ['current-staff'],
    queryFn: () => getCurrentStaff(),
    ...config,
  });
};
