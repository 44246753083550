import { Avatar, AvatarProps, Box, Typography } from "@mui/material";
import React from "react";
import { string } from "zod";


type UserAvatarProps = {
  picture?: string|null, 
  label?: string|null, 
  size: number;
} & AvatarProps;

export const UserAvatar: React.FC<UserAvatarProps> = ({ label, picture, size, ...props }) => {

  if (picture) {
    return (
      <Avatar
        src={picture}
        {...props}
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          ...props.sx,
        }}
      />
    );
  } else if (label) {
    return (
      <Avatar
        {...props}
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          ...props.sx,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            textTransform: 'uppercase'
          }}
        >
          {label}
        </Typography>
      </Avatar>
    );
  } else {
    return (
      <Box 
        sx={{
          height: size,
          width: size,
          bgcolor: 'background.default',
          borderRadius: '50%'
        }}
      />
    );
  }

}