import { IntlProvider } from "react-intl";
import { createContext, useEffect, useState } from "react";
import { useLocalStorage } from "hooks";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProviderProps } from "@mui/lab";
import { useQueryClient } from "./react-query";
import { useUser } from "./auth";
import { Locale, defaultLocale, loadDayjsLocale, loadDatePickerLocale, supportedLocales } from "@kemtai/locale";
import { useCurrentStaff } from "features/staff";


export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_FORMAT = () => dayjs.localeData().longDateFormat('ll').replace(/,?\s*YYYY/g, "");

export const LANGUAGE_OPTIONS: { value: Locale, label: string }[] = [
  { 
    value: "en-US", 
    label: "English (US)"
  },
  { 
    value: "he", 
    label: "Hebrew"
  },
  { 
    value: "es", 
    label: "Spanish"
  },
  {
    value: "es-US", 
    label: "Spanish (US)"
  },
  { 
    value: "it", 
    label: "Italian"
  },
  { 
    value: "de", 
    label: "German"
  },
  { 
    value: "pl", 
    label: "Polish"
  },
  {
    value: "pt-BR",
    label: "Portuguese (Brazil)"
  }
];

export const isRTL = (lang: Locale) => {
  return [
    "he"
  ].includes(lang);
}

export interface LocaleContextType {
  locale: Locale;
  direction: "ltr"|"rtl";
  setLocale: (locale: Locale) => void;
}

export const LocaleContext = createContext<LocaleContextType>({
  locale: defaultLocale,
  direction: "ltr",
  setLocale: () => undefined,
});


const getDefaultLocale = () => {
  const browserLanguage = navigator.language as Locale;
  
  if (supportedLocales.includes(browserLanguage)) {
    return browserLanguage;
  }

  const simplifiedBrowserLanguage = browserLanguage.slice(0, browserLanguage.indexOf("-")) as Locale;

  if (supportedLocales.includes(simplifiedBrowserLanguage)) {
    return simplifiedBrowserLanguage;
  }

  return defaultLocale;
}


export const LocaleProvider: React.FC = ({ children }) => {
  
  const { value: locale, setValue: setLocale } = useLocalStorage<Locale>('locale', getDefaultLocale());
  const [datePickerLocaleText, setDatePickerLocaleText] = useState<LocalizationProviderProps['localeText']|undefined>(undefined);

  const staff = useCurrentStaff();

  const queryClient = useQueryClient();
  
  useEffect(() => {
    if (staff.data?.metadata?.preferences?.lang) {
      setLocale(staff.data.metadata.preferences.lang as Locale);
    }
  }, [staff]);
  
  const direction = ["he"].includes(locale) ? "rtl" : "ltr";

  const [messages, setMessages] = useState(undefined);

  useEffect(() => {
    async function changeLocale() {
      try {
        const response = await fetch(`/locale/${locale}.json`);
        const messages = await response.json();
        setMessages(messages);
      } catch (e) {
        setMessages(undefined);
      }
      
      await loadDayjsLocale(locale);
      dayjs.locale(locale);

      const datePicketLocaleText = await loadDatePickerLocale(locale);
      setDatePickerLocaleText(datePicketLocaleText);

      queryClient.invalidateQueries();
    }

    changeLocale();
  }, [locale]);

  return (
    <IntlProvider 
      messages={messages} 
      locale={locale}
      defaultLocale={defaultLocale}
    >
      <LocaleContext.Provider
        value={{
          locale,
          direction,
          setLocale,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={locale}
          localeText={datePickerLocaleText}
        >
          {children}
        </LocalizationProvider>
      </LocaleContext.Provider>
    </IntlProvider>
  );

}
