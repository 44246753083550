import { ChatMessage } from '../types';
import { axios } from 'libs/axios';
import { useQuery, QueryConfig, PaginationOptions, PaginationResults } from 'libs/react-query';


export const getUnreadChatMessages = (): Promise<ChatMessage[]> => {
  return axios.get(`care/chat-messages/`, {
    params: {
      unread: true
    }
  });
};

type UseUnreadChatMessagesOptions = {
  config?: QueryConfig<typeof getUnreadChatMessages>
};

export const useUnreadChatMessages = ({ config }: UseUnreadChatMessagesOptions = {}) => {
  return useQuery<ChatMessage[]>({
    queryKey: ['unread-chat-messages'],
    queryFn: getUnreadChatMessages,
    ...config,
  });
};
