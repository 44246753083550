import React from "react";
import { useUpdateCurrentStaff } from 'features/staff';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Button } from "@kemtai/ui";
import { TermsOfUseContent } from "./Content";


type TermsOfUseDialogProps = {
  open: boolean;
	onClose: () => void;
  standalone?: boolean;
};

export const TermsOfUseDialog: React.FC<TermsOfUseDialogProps> = ({ open, onClose, standalone }) => {

  const updateCurrentStaffMutation = useUpdateCurrentStaff();

  const handleOnAgree = () => {
		updateCurrentStaffMutation.mutate({
			data: {
				is_waiver_approved: true
			}
		});

		onClose();
	}

  return (
    <Dialog maxWidth={'md'} open={open}  sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0,0,0,.85)' } }}>
      <DialogTitle sx={{fontSize:22}}>Terms of Use</DialogTitle>

      <DialogContent sx={{
          "& *": {fontFamily:'Gilroy,Gilroy-Light,Arial,Helvetica,sans-serif', fontSize: '1rem', lineHeight:'1.3em'},
          "& p:last-child": {textAlign:'right', fontStyle:'italic'},
        }} >

        <TermsOfUseContent />

      </DialogContent>

      <DialogActions sx={{padding:2}}>
        { standalone
          ? <Button variant="outlined" onClick={onClose} >Close</Button>
          : <Button variant="contained" onClick={handleOnAgree} >
              I Agree to the Terms of Use
            </Button>
        }
      </DialogActions>
    </Dialog>
  )

}
