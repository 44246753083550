import { axios } from 'libs/axios';
import { useMutation, MutationConfig, queryClient } from 'libs/react-query';
import { useNotificationStore } from 'stores';
import { z } from 'zod';
import { contactSupportSchema } from '../schemas';

export type ContactSupportDTO = {
  data: z.infer<typeof contactSupportSchema>
};

export const contactSupport = ({ data }: ContactSupportDTO): Promise<void> => {
  return axios.post('care/contact-support/', data);
};

type UseContactSupportOptions = {
  config?: MutationConfig<typeof contactSupport>;
};

export const useContactSupport = ({ config }: UseContactSupportOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    mutationFn: contactSupport,
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Message was sent',
      });
    },
    ...config,
  });
};
