import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Card, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/logo_dark.png';
import errorIllustration from 'assets/images/error.png';


type ErrorFallbackProps = {
  eventId: string;
};

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ eventId }) => {

  const navigate = useNavigate();
  const theme = useTheme();
	const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  
  const handleGoHomeClick = () => {
    navigate("/");
    window.location.reload()
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <img
        src={logo}
        alt="Kemtai"
        style={{
          height: isMdUp ? '20px' : '15px',
          position: 'absolute',
          top: isMdUp ? '50px' : '30px',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      />

      <Card
        sx={{
          px: isMdUp ? 4 : 1,
          py: 4,
          textAlign: 'center'
        }}
      >
        <img
          src={errorIllustration}
          alt="error"
          width="70%"
        />

        <Box>
          <Typography 
            variant={isMdUp ? "h3" : "h4"} 
            mt={4}
          >
            We've encountered an unexpected error
          </Typography>

          <Typography
            mt={1}
          >
            Try to refresh the page or navigate home.
          </Typography>
        </Box>

        <Box 
          display="flex"
          gap={2}
          mt={4}
          justifyContent="center"
          mx={isMdUp ? 4 : 2}
        >
          <Button 
            variant="contained" 
            onClick={handleGoHomeClick}
            fullWidth
          >
            Go Home
          </Button>

          <Button 
            variant="contained" 
            onClick={() => window.location.reload()}
            fullWidth
          >
            Refresh
          </Button>
        </Box>

        <Typography
          variant="subtitle1"
          mt={4}
          sx={{
            fontSize: "default"
          }}
        >
          Error ID: {eventId}
        </Typography>
      </Card>
    </Container>
  );

}