import { AxiosError, AxiosResponse } from 'axios';
import {
  QueryClient,
  QueryFunctionContext,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseMutationOptions,
  DefaultOptions,
  UseQueryOptions,
  UseQueryResult,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { axios } from './axios';


const queryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<ReturnType<FnType>>;

export type QueryConfig<T extends (...args: any) => any> = Omit<UseQueryOptions<ExtractFnReturnType<T>>, 'queryKey' | 'queryFn'>;

export type InifinteQueryConfig<T extends (...args: any) => any> = Omit<UseInfiniteQueryOptions<ExtractFnReturnType<T>>, 'queryKey' | 'queryFn'>;

export type MutationConfig<T extends (...args: any) => any> = UseMutationOptions<ExtractFnReturnType<T>, AxiosError, Parameters<T>[0]>;

export type PaginationOptions = {
  page?: number;
  pageSize?: number;
  ordering?: string;
  exclude?: string[];
};

export type PaginationResults<T> = {
  count: number;
  next: string|null;
  previous: string|null;
  results: T[];
};

export { useQuery, useMutation, useInfiniteQuery, useQueryClient };
