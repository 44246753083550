import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import React from 'react';

const GlobalStyles = () => {
	return (
		<MUIGlobalStyles
			styles={{
				html: {
					height: '100%',
				},
				body: {
					margin: 0,
					padding: 0,
					lineHeight: 1,
				},
				'#root': {
				},
				a: {
					textDecoration: 'none',
					color: 'inherit',
				},
				'*::-webkit-scrollbar': {
					width: '10px',
					height: "10px"
				},
				'*::-webkit-scrollbar-track': {
					backgroundColor: '#D3DCE4',
					borderRadius: '10px',
				},
				'*::-webkit-scrollbar-thumb': {
					backgroundColor: '#B7C0C6',
					borderRadius: '10px',
				},
				'*::-webkit-scrollbar-thumb:hover': {
					backgroundColor: '#a8bbbf',
				}
			}}
		/>
	);
};

export default GlobalStyles;
