import axios from 'axios';
import { AuthInterceptorConfig, TokenRefreshRequest } from './types';
import { applyStorage, clearAccessToken, clearRefreshToken, getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from './storage';
import { jwtDecode, JwtPayload } from 'jwt-decode';


let expireLeeway = 10;
let useRefreshToken = true;
let refreshRequestPromise: Promise<string | undefined> | undefined = undefined;

const isTokenExpired = (token: string): boolean => {
  if (!token) {
    return true;
  }

  const decoded = jwtDecode<JwtPayload>(token);
  const expiration = decoded.exp;

  if (!expiration) {
    return true;
  }

  const expiresIn = expiration - Date.now() / 1000;
  return !expiresIn || expiresIn <= expireLeeway;
}

const refreshToken = async (requestRefresh: TokenRefreshRequest): Promise<string> => {
  const refreshToken = getRefreshToken();

  if (useRefreshToken && !refreshToken) {
    throw new Error('No refresh token available');
  }

  try {
    const newTokens = await requestRefresh(refreshToken!);
    setAccessToken(newTokens.access);

    if (newTokens.refresh) {
      setRefreshToken(newTokens.refresh);
    }
    return newTokens.access
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      
      if (status === 401 || status === 422) {
        clearAccessToken();
        clearRefreshToken();

        throw new Error(`Got ${status} on token refresh; clearing both auth tokens`);
      }
    }

    if (error instanceof Error) {
      throw new Error(`Failed to refresh auth token: ${error.message}`);
    } else {
      throw new Error('Failed to refresh auth token and failed to parse error');
    }
  }
}


export const refreshTokenIfNeeded = async (
  requestRefresh: TokenRefreshRequest
): Promise<string | undefined> => {
  let accessToken = getAccessToken();

  if (!accessToken || isTokenExpired(accessToken)) {
    accessToken = await refreshToken(requestRefresh);
  }

  return accessToken;
}


export const authInterceptor = ({
  requestRefresh,
  getStorage,
  useRefreshToken: _useRefreshToken = true,
}: AuthInterceptorConfig) => {
  useRefreshToken = _useRefreshToken;

  applyStorage(getStorage())

  return async (requestConfig: any): Promise<any> => {
    if (useRefreshToken && !(getRefreshToken())) return requestConfig;

    let accessToken = undefined;

    if (refreshRequestPromise) {
      accessToken = await refreshRequestPromise;
    }

    if (!accessToken) {
      try {
        refreshRequestPromise = refreshTokenIfNeeded(requestRefresh);
        accessToken = await refreshRequestPromise;

        refreshRequestPromise = undefined;
      } catch (error: unknown) {
        refreshRequestPromise = undefined

        if (error instanceof Error) {
          throw new Error(
            `Unable to refresh access token for request due to token refresh error: ${error.message}`
          )
        }
      }
    }

    if (accessToken && requestConfig.headers) {
      requestConfig.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return requestConfig;
  }
}