import { Embeddable, WorkoutCustomization } from "@kemtai/web-sdk";

export type ExerciseProtocolOptions = {
  exerciseProtocolId: string;
} & Partial<WorkoutCustomization>;

export type ExerciseProtocolEvents = {
  result: { resultId: string };
  close: undefined;
};

class ExerciseProtocol extends Embeddable<ExerciseProtocolOptions, ExerciseProtocolEvents> {

  runType = "care";
  
  name = "exercise-protocol";

  handleEmbeddableMessage = (message: MessageEvent) => {
    if (message.data.type === "result_id") {
      this.emit("result", { resultId: message.data.result_id });
    } else if (message.data.type === "close") {
      this.emit("close", undefined);
    }
  }

}

export { ExerciseProtocol };