import { unitSystemEnum } from "features/units";
import { z } from "zod";

export const clinicPreferencesSchema = z.object({
  save_patient_pii: z.boolean(),
  require_phone_number: z.boolean(),
  disable_websocket: z.boolean(),
  show_results_insights: z.boolean(),
  lang: z.string(),
  country_code: z.string(),
  unit_system: unitSystemEnum,
});

export const organizationPermissionsEnum = z.enum([
  "manage_educational_contents",
  "manage_exercise_protocols",
  "manage_forms",
  "manage_functional_assessments",
  "manage_metrics",
  "manage_rom_assessments",
  "manage_tasks",
  "manage_sts_assessments",
]);

export const clinicSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  key: z.string(),
  logo: z.string().url(),
  tenant_key: z.string(),
  preferences: clinicPreferencesSchema.partial(),
  permissions: z.array(organizationPermissionsEnum),
  is_active: z.boolean(),
});

export const updateClinicSchema = clinicSchema.pick({
  preferences: true
});