import React, { useContext, useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import GlobalStyles from './globalStyles';
import { composeTheme } from './themes';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { AppearanceOptions } from '@kemtai/web-sdk';



type ThemeContextValue = {
	appearance: AppearanceOptions|undefined;
	setAppearance: (appearance?: AppearanceOptions) => void;
};

export const ThemeContext = React.createContext<ThemeContextValue>({} as ThemeContextValue);

export const EmbeddableThemeProvider: React.FC = ({ children }) => {

  const [appearance, setAppearance] = useState<AppearanceOptions>();

  // useEffect(() => {
  //   document.dir = direction;
  // }, [direction]);

  const theme = composeTheme({
    direction: "ltr",
    appearance,
  });

  return (
    <ThemeContext.Provider
      value={{
        appearance,
        setAppearance,
      }}
    >
      <MuiThemeProvider 
        theme={theme}
      >
        <CssBaseline />
        <GlobalStyles />

        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );

  // if (direction === "rtl") {
  //   const cacheRtl = createCache({
  //     key: 'muirtl',
  //     stylisPlugins: [prefixer, rtlPlugin],
  //   });

  //   return (
  //     <CacheProvider value={cacheRtl}>
  //       <ThemeContext.Provider
  //         value={{
  //           palette,
  //           setPalette,
  //         }}
  //       >
          
  //       </ThemeContext.Provider>
  //       <MuiThemeProvider 
  //         theme={theme}
  //       >
  //         <CssBaseline />
  //         <GlobalStyles />

  //         {children}
  //       </MuiThemeProvider>
  //     </CacheProvider>
  //   );
  // } else {
  //   return (
  //     <MuiThemeProvider 
  //       theme={theme}
  //     >
  //       <CssBaseline />
  //       <GlobalStyles />

  //       {children}
  //     </MuiThemeProvider>
  //   );
  // }

}

export const useEmbeddableTheme = () => {
	const ctx = useContext(ThemeContext);

	return ctx;
};
