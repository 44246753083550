import React from 'react';
import isFunction from 'lodash/isFunction'
import isBoolean  from 'lodash/isBoolean'

import { observable, makeObservable } from "mobx";
import LoggingService from '@kemtai/logger';

type Props = Object|null
type RouteSpecification = string | [string , Props, boolean?] | {path:string, props:Props, external?:boolean}
type CheckFunc = (path : string, props?:any) => boolean
type ShowURLFunc = (path:string) => string

type AuxRouteSpec = {
  showURL?      :boolean|string|ShowURLFunc,
  external?     :boolean,
  preConditions?:any
}

function zip<V>(keys:string[], values:V[]) {
  const res:{[key:string]:V} = {}
  for(let index=0; index < keys.length; index++){
    res[keys[index]] = values[index]
  }
  return res
}

export function removeLeadingSlashes(path:string){
  return path.replace(/^\/+/,"")
}

export function removeTrailingSlashes(path:string){
  return path.replace(/\/+$/,"")
}

export function removeExtraSlashes(path:string){
  return removeTrailingSlashes(removeLeadingSlashes(path))
}

const idRegexp  = "[a-zA-Z_][a-zA-Z0-9_\-]+"
const argRegexp = `:(${idRegexp})`
const valRegexp = "([^/]+)"

function pathToRegexp(path:string){
  const args = [...(path as any).matchAll(new RegExp(argRegexp,"g"))].map(m=>m[1])
  if (args.length) {
    let matchRegexp = path.replace(new RegExp(argRegexp,"g"), valRegexp)
    matchRegexp = `^${matchRegexp}$`
    return {args, matchRegexp};
  }
  return {args:[]}
}


export class Route  {
  path       : string
  component  : any
  props?     : any
  args       : string[]
  matchRegexp: RegExp|undefined = undefined

  showURL  : boolean | string | ShowURLFunc=true
  external :  boolean
  preConditions : CheckFunc|null = null
  redirect : string | null = null

  constructor (path:string, component:any, props?:any, aux?:AuxRouteSpec){
    path = removeExtraSlashes(path)
    this.path       = path
    this.component = component
    this.props = props

    this.showURL       = aux?.showURL === undefined ? true : aux.showURL
    this.preConditions = aux?.preConditions || null
    this.external      = aux?.external === undefined ? true : Boolean(aux?.external)

    const components = this.path.split("/:")


    const {args,matchRegexp} = pathToRegexp(path)
    this.args   = args
    if (matchRegexp) {
      this.matchRegexp =  new RegExp(matchRegexp)
    }
  }

  get simple():boolean {
    return this.args.length === 0
  }

  match(path:string, external:boolean) {
    path = removeExtraSlashes(path)

    if (external && !this.external) {
      return false
    }

    if (this.simple) {
      return  path === this.path ? {} : null
    } else {
      const pathStr = path.length && path.includes('?') ? path.split('?')[0] : path
      const values = pathStr.match(this.matchRegexp!)
      //print(">>>>>>>>>>>>>>>>",this.path, values)
      if (values){
        values.shift()
        //print("**",this.args,values)
        return zip(this.args,values)
      }
    }
    return null
  }

  checkPreConditions(path : string, props?:any) : boolean {
    //console.log("checkPreConditions")
    if (!this.preConditions) {
      return true
    }
    return this.preConditions(path,props)
  }

  show(page:string) : string {
    if (isFunction(this.showURL)) {
      return this.showURL(page)
    } else if (isBoolean(this.showURL)) {
      return this.showURL ? page : ""
    } else {
      return this.showURL
    }
  }
}


class Router  {
  routes : Route[] = []
  defaultRoute :string = ""
  component : any = null
  path : RouteSpecification = "/"
  pathname : string = ""
  props : any = {}
  stack : [string,any][] = []
  readonly origURL: Location;

  flowName:string = ''
  flowList:any[] = []
  tempList:Route[] = []

  urlParams:any[] = []

  constructor() {
    makeObservable(this, {
      component: observable,
      flowName: observable,
    });


    window.onpopstate = (event:any) => {
      console.log(`location:>>>>>> ${JSON.stringify(event.state)}`)
      this.navigate(event.state.path,event.state.props,false,true)
    }

    this.origURL = {...window.location};

    this.urlParams = this.urlParamsCompare()

  }

  disable() {
    window.onpopstate = () => {};
  }

  changeDefaultRoute(route:string){
    this.defaultRoute = route;
    // this.redirect("",this.defaultRoute)
    // this.redirect("/",this.defaultRoute)
  }

  addToRouts(r:Route, props?:any){
    if(props && props.flowName){
      if(this.flowList[props.flowName]){
        this.flowList[props.flowName].push(r)
      } else {
        this.flowList[props.flowName] = [r]
      }
    } else {
      this.routes.push(r)
    }
  }

  route(path:string, component:any,  props?:any, aux?:AuxRouteSpec){
    const r = new Route(path, component, props, aux)
    this.addToRouts(r, props)
  }

  setFlow = (name:string):void=>{
    const routes = (this.flowList as any)[name]
    if(routes){
      this.flowName = name
      this.tempList = [...this.routes]
      this.routes = [...routes]
    } else {
      if(this.tempList.length){
        this.routes = [...this.tempList]
        this.tempList = []
      }
      this.flowName = ''
    }
    this.component = this.enroute(document.location.pathname)
  }

  redirect(path:string, to:string, props?:any ){
    const r = new Route(path,null)
    r.redirect = to
    this.addToRouts(r, props)
  }

  enroute(path : string, props?:any, external:boolean=false, back:boolean=false):any{
    for(let route of this.routes) {
      let match = route.match(path, external)

      if (match) {
        //print("MATCH:",match)

        if (!route.checkPreConditions(path, props)){
          //console.log("checkPreConditions fail")
          return this.pop()
        }

        if (route.redirect){
          return this.enroute(route.redirect, route.props)
        }


        const Comp  = route.component
        const defaultProps = route.props


        const urlProps = { urlParams: [...this.urlParams]}

        let mergedProps = {...defaultProps, ...props, ...urlProps, ...match}


        if (!back) {
          const show =  route.show(path)
          //print("======================>",show,path,route)
          if (show) {
            const urlSearch = this.origURL.search
            window.history.pushState({path, props}, "",  show.replace(/^\/*/,"/") + urlSearch )
          }
        }
        const Wrapper = (props:any)=>{return <Comp {...props}/>}
        return (<Wrapper {...mergedProps}/>)
      }
    }
    return this.enroute(this.defaultRoute) 
    return this.notFound()
    //return (<h1>No route for {path}</h1>)
    //return null
  }

  navigate(path:string, props:any={}, external:boolean = false, back:boolean = false) {
    // print("[[[ navigate>>>>>>>>:",path)
    //const [path, props] = toPair(r)
    this.path = {path, props, external}
    this.pathname = path
    this.props    = props
    this.urlParams = this.urlParamsCompare(path)
    this.component = this.enroute(path, props,external,back)
    // print("[[[ navigate>>>>>>>>:",this.component)
    return this.component
  }

  fakeNavigate(path:string, props:any={}, external:boolean = false, back:boolean = false) {
    this.path = {path, props, external}
    this.pathname = path
    this.props    = props
    const component = this.enroute(path, props,external,back)
    return component
  }

  notFound = ():void=>{
    LoggingService.event("page not found")
    this.navigate("/404");
  }


  navigateHome = (): void => {
    // this.controller.ui.auth.close(); // TODO
    // this.controller.workout.init(); // TODO
    this.pushCurrent();
    // this.navigate("/");
    LoggingService.event("navigate home")
    this.navigate(this.defaultRoute);
  }




  urlParamsCompare = (path:string = '')=>{
    const search = path && path.includes('?') ? path.split('?')[1] : this.origURL.search.replace('?', '')
    let params: any[] = []
    if(search.length){
      search.split('&').forEach(param=>{
        const res = param.split('=')
        params.push({name:res[0], value:res[1]})
      })
    }
    return params
  }

  urlParamsValue = (name:string|null=null)=>{
    if(!this.urlParams.length){
      return null
    }
    if(!name){
      return this.urlParams.map(param=>param.value)
    }
    const value = this.urlParams.find(param => param.name === name)
    return value ? value.value : null
  }

  urlParamsAdd = (name: string, value:any=true)=>{
    if(name){
      if(this.urlParamsValue(name)){
        return
      }
      this.urlParams.push({
        name: name,
        value: value
      })
      this.origURL.search = '?'+this.urlParams.map((p)=>`${p.name}=${p.value}`).join('&')
    }
  }

  urlParamsRemove = (name: string)=>{
    const idx = this.urlParams.findIndex(param => param.name === name)
    if(idx){
      this.urlParams.splice(idx, 1)
    }
    this.origURL.search = '?'+this.urlParams.map((p)=>`${p.name}=${p.value}`).join('&')
  }

  urlParamsClear = ()=>{
    this.origURL.search = ''
    this.urlParams = []
  }



  pushCurrent(){
    this.push(this.pathname,this.props)
  }
  push(path:string, props:any={}) {
    this.stack.push([path,props])
  }

  pop(){
    //print("[[[ pop>>>>>>>>:")

    if (this.stack.length) {
      const [path,props] = this.stack[this.stack.length-1]
      return this.navigate(path,props)
    } else {
      return this.navigate(this.defaultRoute)
    }
  }

  peek(){
    if (this.stack.length) {
      const [path,props] = this.stack[this.stack.length-1]
      //print("[[[ pop>>>>>>>>:",path)
      return path;
    } else {
      return "";
    }
  }

  removeLeadingSlashes(path:string) {
    return removeLeadingSlashes(path);
  }


}

const router = new Router();
export default router;
