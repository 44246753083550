import React, { useCallback, useEffect, useState } from "react";
import { ExerciseProtocolResultOptions } from "@kemtai/web-care-sdk";
import { useKemtai } from "../useKemtai";
import { EmebeddableComponentProps } from "../types";
import { EmbeddableShape } from "@kemtai/web-sdk";


export type ExerciseProtocolResultProps = EmebeddableComponentProps<ExerciseProtocolResultOptions>;

export const ExerciseProtocolResult = ({
  style,
  ...options
 }: ExerciseProtocolResultProps) => {

  const { kemtaiInstance } = useKemtai();

  const [embeddable, setEmbeddable] = useState<EmbeddableShape>();

  useEffect(() => {
    const embeddable = kemtaiInstance.createEmbeddable("exercise-protocol-result", options);

    setEmbeddable(embeddable);
  }, []);

  useEffect(() => {
    if (embeddable && options.appearance) {
      embeddable.localAppearance = options.appearance;
      embeddable.setAppearance();
    }
  }, [options.appearance]);
  
  const containerRef = useCallback((node: HTMLDivElement) => {
    if (embeddable && node) {
      embeddable.mount(node);
    }
  }, [embeddable]);

  return (
    <div 
      ref={containerRef}
      style={style}
    />
  );

}