import React, { useEffect, useRef, useState } from 'react';

import { API_URL } from 'config';
import { useQueryClient } from 'libs/react-query';
import { storage } from 'libs/storage';
import { ChatMessage } from 'features/chat';
import { useLocalStorage } from 'hooks';
import { useClinic } from 'features/clinic';


const MAX_RETRIES = 4;


type WebSocketContextValue = {
	sendMessage: (data: string, callback?: () => void) => void;
};

const WebSocketContext = React.createContext<WebSocketContextValue>({} as WebSocketContextValue);

interface WebSocketProviderProps {
	children: React.ReactNode;
}

function WebSocketProvider({ children }: WebSocketProviderProps) {
	const queryClient = useQueryClient();
	const websocket = useRef<WebSocket|null>();
	const [token, setToken] = useState(storage.getAccessToken());
	const connectionResolvers = useRef<any[]>([]);
	const { clinic } = useClinic();

	useEffect(() => {
		const disableWebsocket = clinic?.preferences.disable_websocket;

		if (clinic && token && !disableWebsocket) {
			websocket.current = new WebSocket(`${API_URL.replace('http', 'ws')}/care/ws/chat/?token=${token}`);

			websocket.current!.onopen = () => {
				console.log('Websocket connected');
				connectionResolvers.current.forEach(r => r.resolve())
			};

			websocket.current!.onmessage = (event) => {
				const data = JSON.parse(event.data);

				if (data?.command === 'chat_message') {
					if (data.sender_user) {
						queryClient.setQueryData<ChatMessage[]>(['chat-messages', data.sender_user], old => [data, ...(old || [])]);
					}
				} else if (data?.command === 'chat_update_seen_status') {
					if (data.receiver_user) {
						queryClient.setQueryData<ChatMessage[]>(['chat-messages', data.receiver_user], old => old?.map(chatMessage => ({ ...chatMessage, seen: chatMessage.receiver_user === data.receiver_user ? true : chatMessage.seen })) || []);
					} else {
						queryClient.setQueryData<ChatMessage[]>(['chat-messages', data.sender_user], old => old?.map(chatMessage => ({ ...chatMessage, seen: chatMessage.sender_user === data.sender_user ? true : chatMessage.seen })) || []);
					}
				}

				queryClient.invalidateQueries(['unread-chat-messages']);
				queryClient.invalidateQueries(['conversations']);
			};
		}

		return () => {
			websocket.current?.close();
		};
	}, [queryClient, token, clinic]);

	useEffect(() => {
		window.addEventListener('storage', () => {
			const token = storage.getAccessToken();

			if (token) {
				setToken(token);
			}
		});
	}, []);

	const waitForConnection = async () => {
		return new Promise((resolve, reject) => {
			connectionResolvers.current.push({ resolve, reject });
		});
	}

	const sendMessage = async (data: string, callback?: () => void, retries = 0) => {
		try {
			websocket.current!.send(data);

			if (callback) {
				callback();
			}
		} catch (error) {
			if (retries < MAX_RETRIES && (error as Error).name === "InvalidStateError") {
				await waitForConnection();
				await sendMessage(data, callback, retries + 1);
			} else {
				throw error;
			}
		}
	}

	return (
		<WebSocketContext.Provider
			value={{
				sendMessage
			}}
		>
			{children}
		</WebSocketContext.Provider>
	);
}

function useWebsocket() {
	return React.useContext(WebSocketContext);
}

export { WebSocketProvider, useWebsocket };
