import { EmbeddablesManager } from "../kemtai";
import { AppearanceOptions } from "../types";
import { mergeAppearance } from "../utils";


type UpdateAuthOptions = {
  token: string;
};

export interface EmbeddableShape {
  options: Record<string, any>;
  eventHandlers: Record<string, ((data: any) => void)[]|undefined>;
  name: string;
  runType: string;
  mount(container: HTMLElement): void;
  setOptions(): void;
  setAppearance(): void;
  setLocale(): void;
  handleEmbeddableMessage(message: MessageEvent): void;
  handleMessage(message: MessageEvent): void;
  updateAuth ({ token }: UpdateAuthOptions): void;
  localAppearance?: AppearanceOptions;
};

export abstract class Embeddable<Options extends Record<string, any>, Events extends Record<string, any> = Record<string, any>> implements EmbeddableShape {

  iframeElement?: HTMLIFrameElement;
  manager: EmbeddablesManager;
  options: Options;
  eventHandlers: { [K in keyof Events]?: ((data: Events[K]) => void)[] } = {};
  localAppearance?: AppearanceOptions;
  runType = "standalone";
  abstract name: string;

  constructor({ 
    options, 
    appearance, 
    manager 
  }: { 
    manager: EmbeddablesManager, 
    appearance?: AppearanceOptions, 
    options: Options 
  }) {
    this.options = options;
    this.manager = manager;

    this.localAppearance = mergeAppearance(this.getDefaultLocalAppearance(), appearance);
  }

  getDefaultLocalAppearance(): AppearanceOptions {
    return {};
  };

  mount = (container: HTMLElement) => {
    this.mountLoader(container);

    window.addEventListener('message', this.handleMessage);

    this.iframeElement = document.createElement('iframe');

    this.iframeElement.style.width = "100%";
    this.iframeElement.style.height = "100%";
    this.iframeElement.style.border = "none";
    this.iframeElement.style.display = "block";
    this.iframeElement.src = this.iframeSrc;
    this.iframeElement.allow = "autoplay; screen-wake-lock; camera; microphone";
    this.iframeElement.allowFullscreen = true;
    this.iframeElement.style.visibility = "hidden";

    container.appendChild(this.iframeElement);

    this.iframeElement.addEventListener("load", () => {
      this.unmountLoader(container);

      if (this.iframeElement) {
        this.iframeElement.style.visibility = "visible";
      }
    });
  }

  mountLoader = (container: HTMLElement) => {
    const appearance = mergeAppearance(this.manager.appearance, this.localAppearance);

    const loaderContainer = document.createElement('div');
    loaderContainer.id = "kemtai-embeddable-loader";
    loaderContainer.style.display = "flex";
    loaderContainer.style.alignItems = "center";
    loaderContainer.style.justifyContent = "center";
    loaderContainer.style.height = "100%";
    loaderContainer.style.width = "100%";
    loaderContainer.style.position = "absolute";
    loaderContainer.style.top = "0";
    loaderContainer.style.left = "0";
    loaderContainer.style.zIndex = "1";
    loaderContainer.style.backgroundColor = appearance.colors?.background ?? "#fff";

    const span = document.createElement("span");
    span.style.width = "40px";
    span.style.height = "40px";
    span.style.display = "inline-block";
    span.style.color = appearance.colors?.primary200 ?? "#1CC3AC";
    span.style.animation = "kemtai-embeddable-loader-span 1.4s linear infinite";

    loaderContainer.appendChild(span);

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("class", "");
    svg.setAttribute("viewBox", "22 22 44 44");
  
    span.appendChild(svg);

    const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    svg.style.display = "block";
    circle.setAttribute("cx", "44");
    circle.setAttribute("cy", "44");
    circle.setAttribute("r", "20.2");
    circle.setAttribute("fill", "none");
    circle.setAttribute("stroke-width", "3.6");
    circle.style.stroke = "currentColor";
    circle.style.strokeDasharray = "80px, 200px";
    circle.style.strokeDashoffset = "0";
    circle.style.animation = "kemtai-embeddable-loader-circle 1.4s ease-in-out infinite";
  
    svg.appendChild(circle);

    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes kemtai-embeddable-loader-span {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      @keyframes kemtai-embeddable-loader-circle {
        0% { stroke-dasharray: 1px, 200px; stroke-dashoffset: 0; }
        50% { stroke-dasharray: 100px, 200px; stroke-dashoffset: -15px; }
        100% { stroke-dasharray: 100px, 200px; stroke-dashoffset: -125px; }
      }
    `;

    document.head.appendChild(style);
    container.appendChild(loaderContainer);
  }

  unmountLoader = (container: HTMLElement) => {
    const loaderContainer = document.getElementById("kemtai-embeddable-loader");

    if (loaderContainer) {
      container.removeChild(loaderContainer);
    }
  }

  setOptions = () => {
    this.iframeElement!.contentWindow?.postMessage({
      type: "options",
      embeddableName: this.name,
      options: {
        runType: this.runType,
        ...this.options
      },
    }, this.embedURL);
  }

  setAppearance = () => {
    if (this.iframeElement) {
      this.iframeElement.contentWindow?.postMessage({
        type: "appearance",
        appearance: mergeAppearance(this.manager.appearance, this.localAppearance),
      }, this.embedURL);
    }
  }

  on = <K extends keyof Events>(event: K, handler: (data: Events[K]) => void) => {
    if (!this.eventHandlers[event]) {
      this.eventHandlers[event] = [];
    }

    this.eventHandlers[event]?.push(handler);
  }

  off = <K extends keyof Events>(event: K, handler: (data: Events[K]) => void) => {
    if (this.eventHandlers[event]) {
      this.eventHandlers[event] = this.eventHandlers[event]!.filter(h => h !== handler);
    }
  }

  emit = <K extends keyof Events>(event: K, data: Events[K]): void => {
    if (this.eventHandlers[event]) {
      this.eventHandlers[event]!.forEach(handler => handler(data));
    }
  }

  setLocale = () => {
    if (this.iframeElement) {
      this.iframeElement.contentWindow?.postMessage({
        type: "locale",
        locale: this.manager.locale
      }, this.embedURL);
    }
  }

  abstract handleEmbeddableMessage(message: MessageEvent): void;

  handleMessage = (message: MessageEvent) => {
    if (message.data.type === "ready") {
      if (this.manager.authHandler.token) {
        this.updateAuth({
          token: this.manager.authHandler.token
        });

        this.setOptions();
        this.setAppearance();
        this.setLocale();
      }
    } else {
      this.handleEmbeddableMessage(message);
    }
  }

  updateAuth = ({ token }: UpdateAuthOptions) => {
    if (this.iframeElement) {
      this.iframeElement.contentWindow?.postMessage({
        type: "token",
        token,
      }, this.embedURL);
    }

    this.setOptions();
  }

  get embedURL(): string {
    if (window.__ENV__?.EMBED_URL) {
      return window.__ENV__.EMBED_URL;
    }

    if (this.manager.environment === "production") {
      return "https://embed.kemtai.com";
    } else {
      return "https://embed.sandbox.kemtai.com";
    }
  }

  get iframeSrc() {
    return `${this.embedURL}/${this.name}/${this.runType}/${window.__KEMTAI_RECORD__ === true ? "?kemtai_record=true" : ""}`;
  }

}