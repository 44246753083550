import React from "react";
import { useIntl } from "react-intl";
import { SettingsForm } from "../SettingsForm";
import { useNotificationStore } from "stores";
import { IStaff, UpdateCurrentStaffDTO, useUpdateCurrentStaff } from "features/staff";
import { z } from "zod";
import { FormDialog } from "components";


const schema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  metadata: z.object({
    preferences: z.object({
      lang: z.string(),
    })
  })
});

type SettingsDialogProps = {
  staff: IStaff;
  triggerButton: React.ReactElement;
};

export const SettingsDialog: React.FC<SettingsDialogProps> = ({ staff, triggerButton }) => {

  const intl = useIntl();

  const updateCurrentStaffMutation = useUpdateCurrentStaff();

  const { addNotification } = useNotificationStore();

  const onSubmit = async (data: UpdateCurrentStaffDTO['data']) => {
    const result = await updateCurrentStaffMutation.mutateAsync({
      data
    });

    addNotification({
      type: 'success',
      title: intl.formatMessage({ defaultMessage: 'Settings updated', id: '878mRN' })
    });
  }

  return (
    <FormDialog
      triggerButton={triggerButton}
      title={intl.formatMessage({ defaultMessage: "Settings", id: 'D3idYv' })}
      id="update-settings"
      schema={schema}
      onSubmit={onSubmit}
      isDone={updateCurrentStaffMutation.isSuccess}
      options={{
        defaultValues: {
          ...staff,
            metadata: {
              ...staff.metadata,
              preferences: {
                ...staff.metadata.preferences,
                lang: staff.metadata?.preferences?.lang
              }
            }
        }
      }}
    >
      {({ formState }) => (
        <SettingsForm />
      )}
    </FormDialog>
  );

}