import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';


type PersonalInfoSettingsFormProps = {

};

export const PersonalInfoSettingsForm: React.FC<PersonalInfoSettingsFormProps> = () => {

  const { formatMessage } = useIntl();
  const { control, formState } = useFormContext();

  return (
    <Box>
      <Typography
        variant="h4"
      >
        <FormattedMessage 
          defaultMessage="Personal info" id="TxlewI"
        />
      </Typography>

      <Box
        display="grid"
        gap={1}
        my={2}
        mx={2}
      >
        <FormInput
          name="first_name"
          label={formatMessage({ defaultMessage: "First name", id: 'pONqz8' })}
          control={control}
          data-testid="first-name-input"
        />

        <FormInput
          name="last_name"
          label={formatMessage({ defaultMessage: "Last name", id: 'txUL0F' })}
          control={control}
          data-testid="last-name-input"
        />
      </Box>
    </Box>
  );

}
