import React from 'react';
import { KemtaiContext } from './provider';


export const useKemtai = () => {
  const ctx = React.useContext(KemtaiContext);

  if (!ctx.kemtaiInstance) {
    // throw Error("You must wrap ");
  }

  return ctx;
};
