import { Box, Collapse } from '@mui/material';
import { useRef } from 'react';
import { CSSTransition, Transition, TransitionGroup } from 'react-transition-group';

import { useNotificationStore } from 'stores/notifications';

import { Notification } from './Notification';

export const Notifications = () => {
	const { notifications, dismissNotification } = useNotificationStore();

	return (
		<TransitionGroup
			component={Box}
			sx={{
				position: 'fixed',
				top: 0,
				right: 0,
				display: notifications.length > 0 ? 'grid' : 'none',
				justifyContent: 'end',
				zIndex: 1301,
				p: 2,
				width: 500,
				gap: 1,
			}}
		>
			{notifications.map((notification) => (
				<Collapse key={notification.id}>
					<Notification notification={notification} onDismiss={dismissNotification} />
				</Collapse>
			))}
		</TransitionGroup>
	);
};
