import React, { Suspense } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'libs/react-query';
import { ThemeProvider } from 'theme';
import { Loader, ConfirmationDialogProvider, FormExitDialogProvider } from '@kemtai/ui';
import { LoggerProvider } from 'libs/logger';
import { WebSocketProvider } from 'libs/webSocket';
import { LocaleProvider } from 'libs/locale';
import { ErrorBoundary } from '@sentry/react';
import { ErrorFallback } from './ErrorFallback';


export const AppProvider: React.FC = ({ children }) => {

  return (
		<Suspense fallback={<Loader fullscreen />}>
      <QueryClientProvider client={queryClient}>
        <LocaleProvider>
          <ThemeProvider>
            <ErrorBoundary
              fallback={
                ({ eventId }) => <ErrorFallback eventId={eventId} />
              }
            >
              <LoggerProvider
                app="care-manage"
              >
                <ConfirmationDialogProvider>
                  <FormExitDialogProvider>
                    {children}
                  </FormExitDialogProvider>
                </ConfirmationDialogProvider>
              </LoggerProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </LocaleProvider>
      </QueryClientProvider>
    </Suspense>
  );

}
