import 'reflect-metadata';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import { IS_EMBEDDABLE, IS_LEGACY_EMBEDDABLE } from 'config';


ReactDOM.render(
  <BrowserRouter 
    {...(IS_EMBEDDABLE && {
      basename: "embeddables"
    })}
    {...(IS_LEGACY_EMBEDDABLE && {
      basename: "embed"
    })}
  >
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
