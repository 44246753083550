import React from 'react';
import { Box, Typography, Drawer, List, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useClinic } from 'features/clinic';
import { useMenuItems } from './useMenuItems';
import { MenuItem } from './MenuItem';
import { UserMenu } from './UserMenu';
import { ClinicSelect } from './ClinicSelect';
import { sidebarWidth } from 'components/AppLayout';


type SidebarProps = {

};

export const Sidebar: React.FC<SidebarProps> = () => {

  const { clinic } = useClinic();

  const theme = useTheme();
	const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const menuItems = useMenuItems();

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      keepMounted
      sx={{
        width: sidebarWidth,
        '& .MuiPaper-root': {
          width: sidebarWidth,
          bgcolor: "secondary.25",
          border: 0,
          pb: 1,
          [theme.breakpoints.down('md')]: {
            borderBottomRightRadius: 32,
            borderTopRightRadius: 32,
          },
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          '&::-webkit-scrollbar-track': {
            visibility: "hidden"
          },
          '&::-webkit-scrollbar-thumb': {
            visibility: "hidden"
          },
          "&:hover": {
           '&::-webkit-scrollbar-track': {
              visibility: "visible"
            },
            '&::-webkit-scrollbar-thumb': {
              visibility: "visible"
            },
          }
      	}
      }}
    >
      <Link
        to="/"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 3,
            mx: 1.5,
            mt: 1.5,
          }}
        >
          <img
            src={clinic?.logo}
            alt={clinic?.name}
            height="40px"
            width="40px"
            style={{
              borderRadius: '10px',
            }}
          />

          <Typography
            variant="h6"
            data-testid="clinic-name"
          >
            {clinic?.name}
          </Typography>
        </Box>
      </Link>
      
      <List
        sx={{
          mx: 1.5,
        }}
      >
        {menuItems.map(menuItem =>
          <MenuItem
            key={menuItem.path}
            menuItem={menuItem}
          />
        )}
      </List>

      <Box flex={1} />

      <ClinicSelect />
      
      <UserMenu />
    </Drawer>
  );

}
