import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const DEFAULT_NOTIFICATION_SHOW_TIME = 3000;

export type ConfirmButtonTransitionState = "default" | "loading" | "success" | "error";
export type ConfirmButtonLabels = Partial<Record<"confirm" | "error", string>>;

type ConfirmButtonProps = {
  label?: string;
  transitionState: ConfirmButtonTransitionState;
  noTransition?: boolean;
} & ButtonProps;

export const ConfirmButton = ({ label, onClick, transitionState, noTransition, color, ...props }: ConfirmButtonProps) => {

  const [displayCompletedActionState, setDisplayCompletedActionState] = useState(false);
  const timeout = useRef<number>();
  const isCompleted = noTransition ? transitionState !== "default" : displayCompletedActionState;
  const isError = transitionState === "error" && isCompleted;

  useEffect(() => {
    if (!noTransition && transitionState === "loading") {
      setDisplayCompletedActionState(true);
    }
  }, [transitionState, noTransition]);

  useEffect(() => {
    if (noTransition) {
      return;
    }

    if ((["error", "success"] as ConfirmButtonTransitionState[]).includes(transitionState)) {
      timeout.current = setTimeout(() => {
        setDisplayCompletedActionState(false);
      }, DEFAULT_NOTIFICATION_SHOW_TIME) as unknown as number;
    } else if (transitionState === "loading") {
      clearTimeout(timeout.current);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [noTransition, transitionState]);

  const renderContent = () => {
    if (transitionState === "loading") {
      return (
        <CircularProgress
          size={24}
          color="inherit"
          sx={{
            position: 'absolute',
          }}
        />
      );
    }

    if (transitionState === "success" && isCompleted) {
      return (
        <CheckIcon
          data-test-id="button-success"
          sx={{
            position: 'absolute',
          }}
        />
      );
    }

    if (transitionState === "error" && isCompleted) {
      return (
        <ErrorOutlineIcon
          sx={{
            position: 'absolute',
          }}
        />
      );
    }

    return null;
  };

  return (
    <Button
      {...props}
      color={isError ? "error" : color}
      onClick={transitionState === "loading" ? undefined : onClick}
      data-test-state={isCompleted ? transitionState : "default"}
    >
      {renderContent()}
      
      <span
        style={{
          opacity: ["loading", "success", "error"].includes(transitionState) && isCompleted ? "0" : "1",
          transition: "ease",
        }}
      >
        {label}
      </span>
    </Button>
  );

}