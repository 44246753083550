import { Form, ROMAssessment, EducationalContent, Task, FunctionalAssessment, ExerciseProtocol, ExerciseProtocolResult, UserCarePlan, STSAssessment } from "./embeddables";
import { AppearanceOptions, EmbeddablesManager, KemtaiInstanceOptions } from '@kemtai/web-sdk';
import { FunctionalAssessmentResult } from "./embeddables/functional-assessment-result";
import { Locale } from "@kemtai/web-sdk";


export class Kemtai {
  
  #manager: EmbeddablesManager;

  constructor(options: KemtaiInstanceOptions) {
    this.#manager = new EmbeddablesManager(options);
  }

  static embeddables = {
    "exercise-protocol": ExerciseProtocol,
    "rom-assessment": ROMAssessment,
    "form": Form,
    "exercise-protocol-result": ExerciseProtocolResult,
    "user-care-plan": UserCarePlan,
    "task": Task,
    "educational-content": EducationalContent,
    "functional-assessment": FunctionalAssessment,
    "functional-assessment-result": FunctionalAssessmentResult,
    "sts-assessment": STSAssessment,
  };

  updateAppearance = (appearance: AppearanceOptions) => {
    this.#manager.setAppearance(appearance);
  }

  updateLocale = (locale: Locale) => {
    this.#manager.setLocale(locale);
  }
  
  createEmbeddable = <
    K extends keyof typeof Kemtai.embeddables,
    T extends InstanceType<typeof Kemtai.embeddables[K]>["options"]
  >(
    name: K,
    { 
      appearance, 
      ...options 
    }: T & { appearance?: AppearanceOptions }
  ) => {
    const embeddable = new Kemtai.embeddables[name]({
      manager: this.#manager,
      options: options as any,
      appearance,
    });

    this.#manager.mountedEmbeddables.push(embeddable);

    return embeddable as InstanceType<typeof Kemtai.embeddables[K]>;
  }

}

type Embeddables = typeof Kemtai.embeddables;

export type EmbeddableOptionsMap = {
  [K in keyof Embeddables]: InstanceType<Embeddables[K]>['options'];
};
