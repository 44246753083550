export const TermsOfUseContent = () => {

  return (
    <>
      <p>
            Kemtai Ltd. ("<b>Kemtai</b>", "<b>we</b>" or "<b>us</b>") welcomes
            you ("<b>you</b>" or "<b>user</b>") to Kemtai's website and/or
            application based platform (collectively, the "<b>Platform</b>").
          </p>
          <p>
            Your use of the Platform and the services and content made available
            on the Platform are subject to these Terms of Use (these "
            <b>Terms</b>") as well as Kemtai's privacy policy located at{" "}
            'https://app.kemtai.com/privacy-policy'
            . By accessing the Platform, you are agreeing to be bound by these
            Terms and all applicable laws and regulations. If you do not agree
            with any of these Terms, you are prohibited from accessing or using
            the Platform.
          </p>
          <p>
            We may revise these Terms from time to time, without prior notice.
            When we do, we will revise the "last updated" date given below. It
            is your responsibility to review these Terms frequently and to
            remain informed of any changes made. The then-current version of
            these Terms will supersede all earlier versions. You agree that your
            continued use of the Services following any such revision, will
            constitute your acceptance of such revised Terms.
          </p>
          <p>
            <b>Our Services</b>
          </p>
          <p>
            Kemtai offers home workout programs through the Platform (the "<b>Services</b>"). We may modify, suspend or discontinue
            (temporarily or permanently) the Services without prior notice.
          </p>
          <p>
            The Services are available only to individual users who 
            have been approved by their clinician as fit to perform physical
            exercises similar to those offered by the Services. By using the
            Services you declare that you are in full compliance with the words
            of this paragraph.
          </p>
          <p>
            Your ability to access and use the Platform and/or the Services
            requires that you meet certain system requirements, which may be
            changed from time to time. These system requirements include one or
            more compatible devices, internet access (additional third party
            fees may apply), and certain software (additional third party fees
            may apply), as well as obtaining updates or upgrades from time to
            time. We recommend using high speed internet access and connecting
            any such compatible devices and/or other hardware directly to an
            electrical outlet. If you cannot meet these requirements, the Services
            may be affected.
          </p>
          <p>
            <b>Registration and Account</b>
          </p>
          <p>
            <b>User Profile</b>
          </p>
          <p>
            Use of some of our Services by you may require registration and
            creating of an account. In case registration of an account is
            required, you will be required to complete an application request
            for registration and access to the Services by submitting your basic
            information, including your name, email address, phone number and
            the information listed below, and, if accepted, you will be able to
            register to the Services and open a personal account (the "
            <b>Account</b>"), with a username that may be based upon the email address
            provided as part of the registration process ("<b>Username</b>") as
            well as a password, or login with Google/Facebook. Your Username as well as your name, email address, exercises and workout scores and
            other details you provide or enable the saving of (<em>e.g.</em>{" "}
            your exercise protocols), will be part of your Account saved on our
            cloud servers. You may receive information and or reminders via email or text messages.
          </p>
          <p>
            Kemtai reserves the right to delete your Account after a significant
            duration of inactivity and you may request that we delete your
            Account by contacting us at{" "}
            <a href='mailto:info@kemtai.com'>info@kemtai.com</a>. You agree to
            provide, maintain and update true, accurate, current, and complete
            information about yourself as prompted by our registration process.
          </p>
          <p>
            You agree not to impersonate any person or entity or misrepresent
            your identity or affiliation with any person or entity, including
            using another person's username, password or other account
            information, or another person's name, likeness, voice, image or
            photograph. You are responsible for maintaining the confidentiality
            of your account and password and for restricting access to your
            account, and you agree to accept responsibility for all activities
            that occur under your account or password. You also agree to notify
            us promptly at <a href='mailto:info@kemtai.com'>info@kemtai.com</a>{" "}
            of any unauthorized use of your Username, password, other account
            information, or any other breach of security that you become aware
            of involving or relating to the Platform.
          </p>
          <p>
            We may suspend or terminate your account and your ability to use the
            Platform or portion thereof for failure to comply with these Terms.
          </p>
          <p>
            <b>
              Recordings, Saving and Use of your images or videos and/or
              Leaderboard ID
            </b>
          </p>
          <p>
            By using the Services, you are giving Kemtai consent to record and
            store your Kemtai exercises (this does not include your images or video, just the locations of your joints and body parts during the exercise and information derived from that) as well as use such recorded exercises. If
            you do not consent to being recorded, you may choose to leave the Service.
          </p>
          <p>
            In addition, Kemtai may offer you the possibility to save
            videos or images of your Kemtai exercises to your Account.
          </p>
          <p>
            UNDER NO CIRCUMSTANCES WILL KEMTAI BE LIABLE IN ANY WAY FOR ANY OF
            YOUR SAVED VIDEOS, INCLUDING, ANY LOSS OR
            DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY SAVED VIDEOS POSTED, EMAILED OR OTHERWISE
            TRANSMITTED.
          </p>
          <p>
            If your saved videos features the image of any person other than
            yourself, you confirm that you have received the permission of the
            relevant person for the use of his/her image by Kemtai in accordance
            with the terms of these Terms, and you agree to indemnify us for any
            losses which we may incur in the event of a well-founded complaint,
            action or claim by any third party concerning our use of this third
            party’s image, derived from your saved video.
          </p>
          <p>
            We will not transfer your recorded video, saved videos or images
            with any 3 <sup>rd</sup> party except as necessary to enable and/or
            enhance and/or otherwise improve the Services and/or Platform, or
            otherwise with your consent.
          </p>
          <p>
            In addition, you agree we may disclose personal and/or identifiable information, saved videos or images if
            required to do so by law or in the good faith believe that such
            preservation or disclosure is reasonably necessary to comply with a
            legal process, to enforce these Terms, to respond to claims that any
            of your saved videos violates the rights of third-parties, or to
            protect the rights, property, or safety of Kemtai, our users and the
            public. You understand that the technical processing and
            transmission of your saved videos may involve transmissions over
            various networks.
          </p>
          <p>
            You agree that we use your saved videos or images
            for improving the quality and performance of our Services.
          </p>
          <p>
            <b>How Can You Help Us Improve?</b>
          </p>
          <p>
            The Services are based on artificial intelligence, as such the more
            data we collect about the way people train, the better they become.
            If you wish you can permit us to use your exercise videos by
            pressing the <b>Record Videos</b> button in the setting page, in the services where this option is available. By
            pressing this button you confirm that we may use your videos for the
            purposes of updating and/or enhancing and/or improving the Services
            and Platform and we may also store and process your videos in AWS
            servers and/or in the State of Israel that is enjoying the benefit
            of an adequacy decision by the European Commission according to
            which the State of Israel is providing an adequate level of
            protection for personal data. Kemtai reserves the right to delete
            your videos once they have been analysed. You may withdraw your
            consent for use of your videos at any time by notifying us at
            info@kemtai.com.
          </p>
          <p>
            <b>User Restrictions</b>
          </p>
          <p>
            Users shall use the Services for exercise purposes. Users are
            prohibited from using the Services for any other purpose. User shall
            be solely and fully responsible for its use of the Platform and the
            Services and for all consequences and results thereof.
          </p>
          <p>
            User agrees not to and shall not permit or assist any other party
            to:
          </p>
          <ul>
            <li>
              Use the Platform, and/or the Services for any illegal, immoral,
              unlawful and/or unauthorized purposes;
            </li>
            <li>
              Interfere with or violate any user's rights to privacy and other
              rights, or harvest or collect personally identifiable information
              about users without their express consent, whether manually or
              with the use of any robot, spider, crawler any search or retrieval
              application, or use other manual or automatic device, process or
              method to access the Platform, the Services and retrieve, index
              and/or data-mine information;
            </li>
            <li>
              Make any use of any content available on or through the Platform
              or the Services on any other application, website or networked
              computer environment for any purpose other than the use of the Services; or
            </li>
            <li>
              Frame or link any information available from the Platform, unless
              permitted by these Terms. If you operate a website and would like
              to utilize information provided by the Platform, we require that
              you not frame or link to specific portions of the Platform, but
              instead provide a link to the Platform.
            </li>
          </ul>
          <p>
            <b>Disclaimer of Warranties</b>
          </p>
          <p>
            TO THE FULLEST EXTENT PERMITTED UNDER LAW, THE PLATFORM AND THE
            SERVICES ARE PROVIDED ON AN "AS-IS" AND "AS AVAILABLE" BASIS AND
            KEMTAI AND ITS AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
            SHAREHOLDERS, EMPLOYEES, SUB-CONTRACTORS, AGENTS AND LICENSORS
            (COLLECTIVELY, "<b>KEMTAI'S</b> <b>REPRESENTATIVES</b>") DISCLAIM
            ALL WARRANTIES OF ANY KIND OR NATURE, EXPRESS OR IMPLIED, INCLUDING
            BUT NOT LIMITED TO WARRANTIES OF TITLE OR NON-INFRINGEMENT OR
            IMPLIED WARRANTIES OF USE, MERCHANTABILITY OR FITNESS FOR A
            PARTICULAR PURPOSE AND THOSE ARISING FROM A COURSE OF DEALING OR
            USAGE OR TRADE.
          </p>
          <p>
            KEMTAI MAKES NO WARRANTY THAT: (I) THE PLATFORM AND/OR THE SERVICES
            ARE OR WILL BE SECURE, TIMELY, RELIABLE, ACCURATE, COMPLETE,
            UNINTERUPTED, ERROR FREE, FREE OF VIRUSES, DEFECTS, WORMS, OR OTHER
            HARMFUL COMPONENTS, (II) ANY ERRORS OR DEFECTS IN THE PLATFORM
            AND/OR THE SERVICES WILL BE CORRECTED, AND/OR (III) THE RESULTS OF
            USING THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS.
            KEMTAI IS NOT AND SHALL NOT BE RESPONSIBLE TO YOU OR TO ANY THIRD
            PARTY FOR THE CONSEQUENCES OF ANY ERRORS, FAULTS OR OMISSIONS
            RELATED TO ANY CONTENT CONTAINED IN THE PLATFORM AND/OR THE
            SERVICES.
          </p>
          <p>
            <b>Physical Activity Notice</b>
          </p>
          <p>
            The Platform includes features that support physical activity. You
            should consider the risks involved and consult with certified
            healthcare advisers and make your medical decisions based on their
            advice and your judgement before engaging in any physical activity.
            Your use of our Services is at your own discretion and risk. Kemtai
            is not a licensed medical care provider and represents that it has
            no expertise in diagnosing, examining, or treating medical
            conditions of any kind, or in determining the effect of any specific
            exercise on a medical condition.
          </p>
          <p>
            Kemtai is not responsible or liable for any injuries or damages you
            may sustain from your use of the Platform and you agree to release
            and discharge Kemtai from any and all claims and/or causes of action
            arising out of your use of the Platform and/or the Services.
          </p>
          <p>
            <b>Limitation of Liability</b>
          </p>
          <p>
            TO THE FULLEST EXTENT PERMITTED UNDER LAW, IN NO EVENT SHALL KEMTAI
            AND/OR KEMTAI'S REPRESENTATIVES BE LIABLE TO YOU OR TO OTHERS FOR
            ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION, ANY DIRECT,
            INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY, CONSEQUENTIAL OR
            EXEMPLARY DAMAGES OF ANY KIND UNDER ANY LEGAL THEORY, RESULTING FROM
            OR ARISING OUT OF YOUR USE OR INABILITY TO USE THE PLATFORM AND/OR
            THE SERVICES AND/OR THE FAILURE OF THE PLATFORM AND/OR THE SERVICES
            TO PERFORM AS REPRESENTED OR EXPECTED OR FROM THE FAILURE OF KEMTAI
            TO PERFORM UNDER THESE TERMS AND/OR FROM ANY OTHER ACT OR OMISSION
            OF KEMTAI OR BY ANY OTHER CAUSE WHATSOEVER, REGARDLESS OF WHETHER
            KEMTAI HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING AND TO THE MAXIMUM
            EXTENT PERMITTED BY LAW, KEMTAI'S AND KEMTAI'S REPRESENTATIVES'
            TOTAL AGGREGATE LIABILITY FOR ALL DAMAGES OR LOSSES WHATSOEVER
            ARISING HEREUNDER AND/OR IN CONNECTION WITH YOUR USE OR INABILITY TO
            USE THE PLATFORM AND/OR THE SERVICES SHALL BE LIMITED TO THE GREATER
            OF: (I) THE AMOUNT OF FEES YOU PAY TO US IN THE TWELVE MONTHS PRIOR
            TO THE ACTION GIVING RISE TO LIABILITY, OR (II) US$100.00.
          </p>
          <p>
            <b>Intellectual Property Rights</b>
          </p>
          <p>
            You acknowledge that the Platform, the Services and any code that
            Kemtai creates to generate or display any content or the pages
            making up the Platform and any and all intellectual property rights
            pertaining thereto, including without limitation, copyrights,
            database rights, domain names, trademarks, trademark applications,
            trade names, service marks and all goodwill associated therewith and
            symbolized thereby, patents, patent applications, inventions,
            discoveries, concepts, improvements, know-how, confidential
            information, trade secrets and design rights, in each case whether
            registered or unregistered and including all applications and rights
            to apply for registration, and all similar or equivalent rights
            (collectively, "<b>Intellectual</b> <b>Property</b> <b>Rights</b>"),
            are (i) solely and exclusively owned by and/or licensed to Kemtai,
            and (ii) are protected by applicable copyright, trademark and other
            intellectual property laws and international conventions and
            treaties.
          </p>
          <p>
            Nothing herein shall be deemed to convey to you an interest in or to
            any Kemtai's Intellectual Property Rights other than a limited
            revocable right of use in accordance with these Terms.
          </p>
          <p>
            The unauthorized posting, reproduction, copying, distribution,
            modification, public display or public performance of copyrighted
            works constitutes infringement of the copyright owner's rights. As a
            condition to your use of the Platform and the Services, you agree
            not to use the Platform, and/or the Services to infringe the
            Intellectual Property Rights of others in any way.
          </p>
          <p>
            If user provides any feedback or suggestions to Kemtai ("
            <b>Feedback</b>"), Kemtai shall have an exclusive, worldwide,
            royalty free, and irrevocable license to incorporate the Feedback
            into any Kemtai current or future products, technologies and/or
            services without user's approval and without any compensation
            payable to user.
          </p>
          <p>
            <b>Termination</b>
          </p>
          <p>
            The Terms shall remain in effect until terminated as set forth
            herein. Your failure to comply with any provision of the Terms, your
            misuse of the Platform and/or the Services shall terminate your
            right to use the Platform and the Services. In such circumstances,
            Kemtai may temporarily or permanently, at Kemtai's discretion, limit
            or terminate your access to the Platform and the Services without
            notice, in addition to any other remedies that may be available to
            Kemtai under any applicable law.
          </p>
          <p>
            If you object to any term hereof, as may be amended from time to
            time, or if you become dissatisfied with the Platform and/or the
            Services, you may terminate the Terms at any time by ceasing your
            use of the Platform and the Services and this will be your sole and
            exclusive remedy in such circumstances.
          </p>
          <p>
            Upon termination of the Terms for any reason all rights granted to
            you under these Terms will immediately terminate and you will
            immediately cease all use of and access to the Platform and the
            Services.
          </p>
          <p>
            The provisions of these Terms which by their nature survive the
            termination of these Terms shall survive termination of these Terms.
            Without limiting the generality of the foregoing, the Disclaimer of
            Warranties, Limitation of Liability, Physical Activity Notice,
            Intellectual Property Rights and Governing Law will survive the
            termination of the Terms for any reason whatsoever.
          </p>
          <p>
            <b>Assignment</b>
          </p>
          <p>
            These Terms are personal to you, and you may not assign your rights
            or obligations hereunder to anyone. Kemtai, however, may assign
            these Terms without your notice or consent.
          </p>
          <p>
            <b>Governing Law and Jurisdiction</b>
          </p>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of the State of Israel without giving effect to its conflict of
            laws rules and you irrevocably submit to the exclusive jurisdiction
            of the courts in the city of Tel Aviv, Israel, which shall have sole
            and exclusive jurisdiction over any matters arising out of, stemming
            from and/or related to your use of the Platform and/or Services.
          </p>
          <p>Last updated: 4 April 2023</p>
    </>
  )

}