import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryKey,
  UseQueryOptions,
  QueryFunction,
  MutationFunction,
  UseMutationOptions,
} from '@tanstack/react-query';
import { Authorizer, IPolicyRule } from './authorizer';


export interface AuthConfig<
  User,
  LoginCredentials,
  SSOCallbackCredentials,
  RegisterCredentials
> {
  userFn: QueryFunction<User|null, QueryKey>;
  loginFn?: MutationFunction<User, LoginCredentials>;
  ssoCallbackFn?: MutationFunction<User, SSOCallbackCredentials>;
  onLoginSuccess?: (user: User) => void;
  registerFn?: MutationFunction<User, RegisterCredentials>;
  logoutFn?: MutationFunction<unknown, unknown>;
  userKey?: QueryKey;
  policyFn?: QueryFunction<IPolicyRule[], QueryKey>;
  userRolesFn?: (user: User) => string[];
}

export function configureAuth<
  User extends { roles: { tenant: string, role: string }[] },
  Error,
  LoginCredentials,
  SSOCallbackCredentials,
  RegisterCredentials
>(config: AuthConfig<User, LoginCredentials, SSOCallbackCredentials, RegisterCredentials>) {
  const {
    userFn,
    userKey = ['authenticated-user'],
    loginFn,
    ssoCallbackFn,
    onLoginSuccess,
    registerFn,
    logoutFn,
    policyFn,
    userRolesFn,
  } = config;

  const useUser = (
    options?: Omit<
      UseQueryOptions<User|null, Error, User, QueryKey>,
      'queryKey' | 'queryFn'
    >
  ) => useQuery(
    userKey,
    userFn,
    options,
  );

  const useLogin = (
    options?: Omit<
      UseMutationOptions<User, Error, LoginCredentials>,
      'mutationFn'
    >
  ) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback((data: User) => {
      queryClient.setQueryData(userKey, data);
      onLoginSuccess?.(data);
    }, [queryClient]);

    return useMutation({
      mutationFn: loginFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user);
        options?.onSuccess?.(user, ...rest);
      },
    });
  };

  const useSSOCallback = (
    options?: Omit<
      UseMutationOptions<User, Error, SSOCallbackCredentials>,
      'mutationFn'
    >
  ) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback((data: User) => {
      queryClient.setQueryData(userKey, data);
      onLoginSuccess?.(data);
    }, [queryClient]);

    return useMutation({
      mutationFn: ssoCallbackFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user);
        options?.onSuccess?.(user, ...rest);
      },
    });
  };

  const useRegister = (
    options?: Omit<
      UseMutationOptions<User, Error, RegisterCredentials>,
      'mutationFn'
    >
  ) => {
    const queryClient = useQueryClient();


    return useMutation({
      mutationFn: registerFn,
      ...options,
    });
  };

  const useLogout = (options?: UseMutationOptions<unknown, Error, unknown>) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback(
      (data: User | null) => queryClient.setQueryData(userKey, data),
      [queryClient]
    );

    return useMutation({
      mutationFn: logoutFn,
      ...options,
      onSuccess: (...args) => {
        setUser(null);
        options?.onSuccess?.(...args);
      },
    });
  };

  function useIsAuthenticated(): boolean {
    const { isSuccess, data } = useUser();

    return isSuccess && !!data;
  }

  function AuthLoader({
    children,
    renderLoading,
    renderUnauthenticated,
    renderError = (error: Error) => <>{JSON.stringify(error)}</>,
  }: {
    children: React.ReactNode;
    renderLoading: () => JSX.Element;
    renderUnauthenticated?: () => JSX.Element;
    renderError?: (error: Error) => JSX.Element;
  }) {
    const { isSuccess, isFetched, data } = useUser();

    if (isSuccess) {
      if (renderUnauthenticated && !data) {
        return renderUnauthenticated();
      }

      return <>{children}</>;
    }

    if (!isFetched) {
      return renderLoading();
    }

    return null;
  }

  function useAuthorization() {
    if (!policyFn || !userRolesFn) {
      return { checkAccess: ({ resource, action }: { resource: string, action: string }) => false };
    }

    const user = useUser();

    const { data: policy } = useQuery(['policy'], policyFn);

    const authorizer = useMemo(() =>
      policy
        ? new Authorizer({
            policy,
          })
        : null
    , [policy]);

    const checkAccess = useCallback(({ resource, action }: { resource: string, action: string }) => {
      if (!user?.data) {
        return false;
      }

      const userRoles = userRolesFn(user.data);
      return authorizer?.authorize(userRoles, resource, action) ?? false;
    }, [user]);

    return { checkAccess };
  };

  function Authorization ({
    resource,
    action,
    forbiddenFallback = null,
    children
  }: {
    forbiddenFallback?: React.ReactNode;
    children: React.ReactNode;
    resource: string;
    action: string;
  }) {
    const { checkAccess } = useAuthorization();

    let canAccess = false;

    canAccess = checkAccess({ resource, action });

    return <>{canAccess ? children : forbiddenFallback}</>;
  };

  return {
    useUser,
    useIsAuthenticated,
    useLogin,
    useSSOCallback,
    useRegister,
    useLogout,
    AuthLoader,
    useAuthorization,
    Authorization,
  };
}

export { IPolicyRule };
