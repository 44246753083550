import { DialogContent, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HelpIcon from '@mui/icons-material/Help';
import { useDisclosure } from "hooks";
import { AboutUsDialog, DialogTrigger } from "components";
import { ContactSupportDialog } from "features/support";
import { useIntl } from "react-intl";


type HelpDialogProps = {
  triggerElement: React.ReactElement;
};

export const HelpDialog: React.FC<HelpDialogProps> = ({ triggerElement }) => {

  const intl = useIntl();

  const aboutUsDialog = useDisclosure();

  const handleAboutUsClick = () => {
    aboutUsDialog.open();
  }

  return (
    <DialogTrigger
      triggerButton={triggerElement}
      title={intl.formatMessage({ defaultMessage: "Help & Support", id: 'Uf3+S6' })}
      maxWidth="md"
    >
      {({ close }) => (
        <>
          <DialogContent
            sx={{
              width: "400px",
            }}
          >
            <List
              dense
            >
              <ListItem>
                <ListItemButton
                  href="https://docs.google.com/document/d/19uLTbMXEtUoVrNj6KAjo7QL-Rj33LEVCG1J4yI3kqzQ"
                  target="_blank"
                >
                  <ListItemIcon>
                    <HelpIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary={intl.formatMessage({ defaultMessage: "User manual", id: '/5QXkz' })} 
                  />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ContactSupportDialog 
                  triggerButton={
                    <ListItemButton>
                      <ListItemIcon>
                        <SupportAgentIcon />
                      </ListItemIcon>
                      <ListItemText 
                        primary={intl.formatMessage({ defaultMessage: "Contact support", id: '5CI3KH' })}
                      />
                    </ListItemButton>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemButton
                  onClick={handleAboutUsClick}
                >
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary={intl.formatMessage({ defaultMessage: "About us", id: 'wGhqrf' })}
                  />
                </ListItemButton>
              </ListItem>
            </List>

            <AboutUsDialog 
              open={aboutUsDialog.isOpen}
              onClose={aboutUsDialog.close}
            />
          </DialogContent>
        </>
      )}
    </DialogTrigger>
  );

}