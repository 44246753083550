import { FetchTokenFunction } from "./types";

export class AuthHandler {

  fetchToken: FetchTokenFunction;
  onToken: (token: string) => void;
  token?: string;
  mountedEmbeddables: any[] = [];

  constructor({ fetchToken, onToken }: { fetchToken: FetchTokenFunction, onToken: (token: string) => void }) {
    this.fetchToken = fetchToken;
    this.onToken = onToken;

    this.#refreshToken();
    window.addEventListener('message', this.#handleMessage);
  }

  #handleMessage = (message: MessageEvent) => {
    if (message.data.type === "token_expired") {
      this.#refreshToken();
    }
  }

  #refreshToken = async () => {
    if (this.fetchToken) {
      const token = await this.fetchToken();
      this.token = token;
      this.onToken(token);
    }
  }
  
}