import { z } from "zod";


export const unitSystemEnum = z.enum([
  "metric", 
  "imperial"
]);


export const unitSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  key: z.string(),
  symbol: z.string().nullable()
});