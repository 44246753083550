
export const supportedLocales = [
  "en-US",
  "he",
  "es-US",
  "it",
  "de",
  "es",
  "pl",
  "pt-BR",
] as const;

export type Locale = typeof supportedLocales[number];

export const defaultLocale = "en-US";

export const loadDayjsLocale = async (locale: Locale) => {
  try {
    let dayjsLocale = locale.toLocaleLowerCase();

    if (dayjsLocale === "en-us") {
      dayjsLocale = "en";
    }

    return await import(`dayjs/locale/${dayjsLocale}.js`);
  } catch {
    return await import("dayjs/locale/en.js");
  }
}

export const loadDatePickerLocale = async (locale: Locale) => {
  const DATE_PICKER_LOCALES_MAP: Record<Locale, string> = {
    "en-US": "en-US",
    "he": "heIL",
    "es-US": "esES",
    "it": "itIT",
    "de": "deDE",
    "es": "esES",
    "pl": "plPL",
    "pt-BR": "ptBR",
  };

  try {
    const datePickerLocale = DATE_PICKER_LOCALES_MAP[locale];
    const localeModule = await import(`@mui/x-date-pickers/locales/${datePickerLocale}.js`);

    return localeModule[datePickerLocale].components.MuiLocalizationProvider.defaultProps.localeText;
  } catch {
    const localeModule = await import("@mui/x-date-pickers/locales/enUS.js");
    return localeModule.enUS.components.MuiLocalizationProvider.defaultProps.localeText;
  }
}