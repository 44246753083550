import { Embeddable } from "@kemtai/web-sdk";

export type FunctionalAssessmentOptions = {
  functionalAssessmentId: string;
};

export type FunctionalAssessmentEvents = {
  result: { resultId: string };
  close: undefined;
};

class FunctionalAssessment extends Embeddable<FunctionalAssessmentOptions, FunctionalAssessmentEvents> {

  runType = "care";
  
  name = "functional-assessment";

  getDefaultLocalAppearance() {
    return {
      colors: {
        primary100: "#9A7BF0",
        primary200: "#6D53CC",
      }
    };
  }
  
  handleEmbeddableMessage = (message: MessageEvent) => {
    if (message.data.type === "result_id") {
      this.emit("result", { resultId: message.data.result_id });
    } else if (message.data.type === "close") {
      this.emit("close", undefined);
    }
  }

}

export { FunctionalAssessment };