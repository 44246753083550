import React, { useState } from "react";
import { SidebarMenuItem } from "./types";
import { Box, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useLocation, useMatch } from "react-router-dom";
import { useDisclosure } from "hooks";

type MenuItemProps = {
  menuItem: SidebarMenuItem;
};

export const MenuItem: React.FC<MenuItemProps> = ({ menuItem }) => {
  
  const location = useLocation();
  const hasActiveChild = menuItem.children?.some(child => child.path && location.pathname.startsWith(child.path));

  const { isOpen, toggle } = useDisclosure(menuItem.label === 'Patients' || hasActiveChild);
  const match = useMatch(menuItem.path ?? "noop");

  const isActive = !!match;
  
  if (menuItem.isPermitted === false) {
    return null;
  }
  
  if (!!menuItem.children) {
    if (menuItem.inline) {
      return (
        <Box
          my={2.5}
        >
          <Typography
            variant="subtitle2"
            display="block"
            mx={1}
            mb={1}
          >
            {menuItem.label}
          </Typography>

          {menuItem.children?.map(childMenuItem =>
            <MenuItem 
              menuItem={childMenuItem}
            />
          )}
        </Box>
      );
    } else {
      return (
        <>
          <ListItem
            disablePadding
          >
            <ListItemButton 
              onClick={toggle}
              sx={{
                borderRadius: 2,
                color: "text.300",
                p: 1,
                gap: 1.2,
                "& .MuiListItemIcon-root": {
                  minWidth: "auto",
                  color: "text.300",
                },
                "&.Mui-selected": {
                  bgcolor: "primary.main",
                  color: "primary.contrastText",
                  "&:hover": {
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                  },
                  "& .MuiListItemIcon-root": {
                    color: "primary.contrastText",
                  }
                }
              }}
            >
              {menuItem.icon &&
                <ListItemIcon
                  sx={{
                    fontSize: "13.5px"
                  }}
                >
                  {menuItem.icon}
                </ListItemIcon>
              }
              
              <ListItemText 
                primary={menuItem.label}
                primaryTypographyProps={{
                  variant: "body4"
                }}
                sx={{
                  m: 0,
                }}
              />
  
              {isOpen 
                ? <ExpandLess 
                    fontSize="inherit"
                  /> 
                : <ExpandMore 
                    fontSize="inherit"
                  />
              }
            </ListItemButton>
          </ListItem>
  
          <Collapse 
            in={isOpen} 
            timeout="auto" 
            unmountOnExit
          >
            <List 
              component="div" 
              disablePadding
              sx={{
                pl: 0.5,
                ml: 2,
                mt: 0.5,
                borderLeftColor: "secondary.100",
                borderLeftStyle: "solid",
                borderLeftWidth: 1
              }}
            >
              {menuItem.children?.map(childMenuItem =>
                <MenuItem 
                  menuItem={childMenuItem}
                />
              )}
            </List>
          </Collapse>
        </>
      );
    }
  } else {
    return (
      <ListItem
        disablePadding
      >
        <ListItemButton 
          component={Link}
          to={menuItem.path}
          selected={isActive}
          sx={{
            borderRadius: 2,
            color: "text.300",
            p: 1,
            gap: 1.2,
            "& .MuiListItemIcon-root": {
              minWidth: "auto",
              color: "text.300",
            },
            "&.Mui-selected": {
              bgcolor: "primary.main",
              color: "primary.contrastText",
              "&:hover": {
                bgcolor: "primary.main",
                color: "primary.contrastText",
              },
              "& .MuiListItemIcon-root": {
                color: "primary.contrastText",
              }
            }
          }}
        >
          {menuItem.icon &&
            <ListItemIcon>
              {menuItem.icon}
            </ListItemIcon>
          }
          
          <ListItemText 
            primary={menuItem.label}
            primaryTypographyProps={{
              variant: "body4"
            }}
            sx={{
              m: 0,
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  }


}