import { Embeddable } from '@kemtai/web-sdk';
import { FormResponse } from './form';


export type STSAssessmentOptions = {
  stsAssessmentId: string;
};

export type STSAssessmentEvents = {
  close: undefined;
  result: { 
    duration: number,
    questionnaireResponse: FormResponse,
  };
};

export class STSAssessment extends Embeddable<STSAssessmentOptions, STSAssessmentEvents> {

  runType = "care";

  name = "sts-assessment";

  getDefaultLocalAppearance() {
    return {
      colors: {
        background: "#F2F5F7",
        paper: "#fff",
        primary100: "#9A7BF0",
        primary200: "#6D53CC",
        primary300: "#6D53CC",
        primary500: "#6D53CC",
      }
    };
  }
  
  handleEmbeddableMessage = (message: MessageEvent) => {
    if (message.data.type === "close") {
      this.emit("close", undefined);
    } else if (message.data.type === "result") {
      this.emit("result", { duration: message.data.duration, questionnaireResponse: message.data.questionnaireResponse });
    }
  }
  
}
