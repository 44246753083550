import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import minMax from 'dayjs/plugin/minMax';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import UpdateLocale from 'dayjs/plugin/updateLocale';
import * as Sentry from "@sentry/react";
import { API_URL, EMBED_URL, ENVIRONMENT, SENTRY_DSN, VERSION } from 'config';
import React, { useEffect } from 'react';
import Router from '@kemtai/components/dist/router';
import { VersionChecker } from 'utils/versionChecker';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";


export const initApp = () => {
  window.__ENV__ = {
    EMBED_URL,
    API_URL,
  };
  
  if (["production", "qa", "sandbox", "staging"].includes(ENVIRONMENT) && !process.env.CI) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: VERSION,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["localhost", /^https:\/\/[a-z]+-api.kemtai.com/, /^https:\/\/api.kemtai.com/],
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          networkDetailAllowUrls: [
            window.location.origin,
            /^https:\/\/[a-z-.]+.kemtai.com/,
          ],
          blockAllMedia: false,
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
    });
  }
  
  if (ENVIRONMENT !== "development") {
    // TagManager.initialize({
    //   gtmId: GTM_ID
    // });
  } 

  const versionChecker = new VersionChecker();
  versionChecker.startChecking();
  
  Router.disable();
  dayjs.extend(weekOfYear);
  dayjs.extend(minMax);
  dayjs.extend(localeData);
  dayjs.extend(LocalizedFormat);
  dayjs.extend(UpdateLocale);
}
