import { Embeddable } from "./embeddable";


export type WorkoutCustomization = {
  skipPositioning: boolean;
  skipOnboarding: boolean;
  skipExerciseIntroduction: boolean;
  skipExerciseResults: boolean;
  noCamera: boolean;
  voiceType: "male"|"female";
  voiceCommand: boolean;
  showTimer: boolean;
  showCounter: boolean;
  showGraph: boolean;
  showScore: boolean;
  showSkeleton: boolean;
  skeletonType: "diamonds"|"dottedLines";
  showVisualFeedback: boolean;
  cuesIntensity: "low"|"regular"|"high";
  scoreDisplay: "numeric"|"graphic";
  enableSoundeffects: boolean;
  soundEffectType: "simple"|"dynamicOne"|"dynamicTwo";
};

export type WorkoutOptions = {
  workoutId: number;
} & Partial<WorkoutCustomization>;

export type WorkoutEvents = {
  close: undefined;
  result: { resultId: string };
};

export class Workout extends Embeddable<WorkoutOptions, WorkoutEvents> {

  name = "workout";
  
  handleEmbeddableMessage = (message: MessageEvent) => {
    if (message.data.type === "result_id") {
      this.emit("result", { resultId: message.data.result_id });
    } else if (message.data.type === "close") {
      this.emit("close", undefined);
    }
  }
  
}