import React from 'react';
import { useQuery, useMutation, useQueryClient, UseMutateAsyncFunction, QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { loginWithEmailAndPassword, getUser, UserResponse, LoginCredentialsDTO, SocialLoginCredentialsDTO, IUser, loginWithSocialAccessToken, SocialRegisterCredentialsDTO, RegisterCredentialsDTO, registerWithEmailAndPassword, registerWithSocialAccessToken, loginWithSSOCode, SSOLoginCredentialsDTO, logout, getPolicy } from 'features/auth';
import { storage } from 'libs/storage';
import { useNotificationStore } from 'stores';
import { useLogger } from 'hooks';
import { configureAuth } from '@kemtai/auth';
import { useClinic } from 'features/clinic';
import * as Sentry from "@sentry/react";


async function handleUserResponse(data: UserResponse) {
	const { access, refresh, user } = data;
	storage.setAccessToken(access);
	storage.setRefreshToken(refresh);

	return user;
}

async function userFn() {
	if (storage.getAccessToken()) {
	  const user = await getUser();

		window.parent.postMessage({
			success: true
		}, "*");

		Sentry.setUser({
			id: user.id,
			email: user.email
		});

	  return user ?? null;
	}

	return null;
}

async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function ssoCallbackFn(data: SSOLoginCredentialsDTO) {
	const response = await loginWithSSOCode(data);
	const user = await handleUserResponse(response);
	return user;
}

async function registerFn(data: RegisterCredentialsDTO) {
	const response = await registerWithEmailAndPassword(data);
	return response;
}

export async function logoutFn() {
	try {
		await logout();
	} catch {}
	
	storage.clearOnLogout();
	window.location.assign(window.location.origin as unknown as string);
}

async function policyFn() {
	const data = await getPolicy();
	return data;
}

function userRolesFn(user: IUser) {
	const { clinic } = useClinic();
  const tenant = clinic?.tenant_key ?? "";
  const userRoles = user.roles.filter((userRole) => userRole.role === "admin" ? true : userRole.tenant === tenant).map((userRole) => userRole.role);
	return userRoles;
}

export const {
	useUser,
	useIsAuthenticated,
	useLogin,
	useSSOCallback,
	useRegister,
	useLogout,
	AuthLoader,
	useAuthorization,
	Authorization
} = configureAuth({
  userFn,
	loginFn,
	ssoCallbackFn,
	registerFn,
	logoutFn,
	policyFn,
	userRolesFn,
});
