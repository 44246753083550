import React, { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { AppLayoutProvider } from './AppLayoutProvider';
import { useIsFetching } from '@tanstack/react-query';
import { CircularProgress, LinearProgress } from '@mui/material';
import { TermsOfUseDialog } from 'components';
import { Loader } from '@kemtai/ui';
import { useCurrentStaff } from 'features/staff';
import { useDialog } from '@kemtai/hooks';
import { AppBar } from '../AppBar';
import { updateUserLogin } from 'features/auth';
import { Sidebar } from '../Sidebar';


export * from './constants';

export const AppLayout = () => {
  
  const headerLeadRef = React.useRef<HTMLDivElement>(null);
  const headerTitleRef = React.useRef<HTMLDivElement>(null);
  const headerActionRef = React.useRef<HTMLDivElement>(null);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const footerRef = React.useRef<HTMLDivElement>(null);

	const { openDialog, closeDialog, isOpen } = useDialog();

  const isFetching = useIsFetching();

	const { data: staff } = useCurrentStaff();

	useEffect(() => {
		if (staff?.is_waiver_approved === false) {
			openDialog("termsofuse");
		}

    updateUserLogin();
	}, [staff]);

	const navigate = useNavigate();

	const navigateHome = () => {
		navigate('/', { replace: true });
	};

  return (
    <Box
      display="grid"
      gridTemplateColumns="auto 1fr"
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          right: 0
        }}
      >
        {isFetching > 0 &&
          <LinearProgress />
        }
      </Box>

      <Sidebar />

      <Box 
        height="100%" 
        width="100%"
        pl="6px"
      >
        <Box 
          component="main" 
          width="100%"
        >
          <AppLayoutProvider context={{ headerRef, headerLeadRef, headerTitleRef, headerActionRef, footerRef }}>
            <Suspense 
              fallback={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="500px"
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Outlet />
            </Suspense>
          </AppLayoutProvider>
        </Box>

        <Box
          ref={footerRef}
          sx={{
            position: 'sticky',
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 10
          }}
        />
      </Box>

			<TermsOfUseDialog
				standalone={staff?.is_waiver_approved !== false}
				open={isOpen("termsofuse")}
				onClose={() => closeDialog("termsofuse")}
			/>
    </Box>
  );
}
