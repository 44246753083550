import { Embeddable } from '@kemtai/web-sdk';


export type FormResponse = {
  question: string;
  row?: string|null;
  value: string|null
}[];

export type FormOptions = {
  formId: string;
};

export type FormEvents = {
  close: undefined;
  response: { response: FormResponse };
};

export class Form extends Embeddable<FormOptions, FormEvents> {

  runType = "care";

  name = "form";

  handleEmbeddableMessage = (message: MessageEvent) => {
    if (message.data.type === "close") {
      this.emit("close", undefined);
    } else if (message.data.type === "result") {
      this.emit("response", { response: message.data.response })
    }
  }

}
