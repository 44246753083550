import { Embeddable } from "./embeddable";


export type WorkoutResultOptions = {
  resultId: string;
};

export class WorkoutResult extends Embeddable<WorkoutResultOptions> {

  name = "workout-result";

  handleEmbeddableMessage = (message: MessageEvent) => {
    
  }
  
}