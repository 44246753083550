import { AppearanceOptions } from "./types";

export const mergeAppearance = (...appearances: (AppearanceOptions|undefined)[]) => {
  let merged: AppearanceOptions = {};

  appearances.forEach(appearance => {
    merged = {
      ...merged,
      ...appearance,
      colors: {
        ...merged?.colors,
        ...appearance?.colors,
      },
    }
  });

  return merged;
}