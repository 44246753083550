import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormSelect } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import { LANGUAGE_OPTIONS } from 'libs/locale';


type LanguageSettingsFormProps = {

};

export const LanguageSettingsForm: React.FC<LanguageSettingsFormProps> = () => {

  const { formatMessage } = useIntl();
  const { control, formState, watch } = useFormContext();

  return (
    <Box>
      <Typography
        variant="h4"
      >
        <FormattedMessage
          defaultMessage="Language" id="y1Z3or"
        />
      </Typography>

      <Box
        display="grid"
        gap={1}
        my={2}
        mx={2}
      >
        <FormSelect
          name="metadata.preferences.lang"
          label={formatMessage({ defaultMessage: "Language", id: 'y1Z3or' })}
          options={LANGUAGE_OPTIONS}
          control={control}
          data-testid="language-select"
        />
      </Box>
    </Box>
  );

}
