import React, { useEffect } from 'react';
import { useDisclosure } from 'hooks';
import { Box, Button, Container, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Typography, DialogProps, IconButton } from '@mui/material';
import { FieldValues, SubmitHandler, UseFormProps, UseFormReturn } from 'react-hook-form';
import { ZodType, ZodTypeDef } from 'zod';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


type DialogTriggerProps = {
  triggerButton: React.ReactElement;
  title?: string|React.ReactElement;
  isDone?: boolean;
  onClose?: () => void;
  children: ({ close }: { close: () => void }) => React.ReactNode;
} & Omit<DialogProps, "open">;

export const DialogTrigger: React.FC<DialogTriggerProps> = ({ children, triggerButton, isDone, title, onClose, ...props }) => {
  const { close, open, isOpen } = useDisclosure();

  const handleClose = () => {
    close();
    onClose?.();

  }
  useEffect(() => {
    if (isDone) {
      handleClose();
    }
  }, [isDone, close]);

  return (
    <>
      {React.cloneElement(triggerButton, { onClick: open })}

      <Dialog
        {...props}
        open={isOpen}
      >
        <DialogTitle>
          {title}

          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              zIndex: 1
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </DialogTitle>

       {children({ close: handleClose })}
      </Dialog>
    </>
  );
};