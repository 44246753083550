import React, { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, useRoutes as reactRouterUseRoutes, useMatch } from 'react-router-dom';
import { AuthLoader, useIsAuthenticated, useUser } from 'libs/auth';
import { AppLayout } from 'components';
import { Loader } from '@kemtai/ui';
import { ClinicProvider } from 'features/clinic';
import { wrapUseRoutes } from '@sentry/react';
import { WebSocketProvider } from 'libs/webSocket';
import { IS_EMBEDDABLE, IS_LEGACY_EMBEDDABLE } from 'config';
import { fetchEmbeddablesToken } from 'features/auth/api/fetchEmbeddablesToken';
import { KemtaiProvider } from '@kemtai/react-care-sdk';
import { useIntl } from 'react-intl';

const useRoutes = wrapUseRoutes(reactRouterUseRoutes);

const Home = lazy(() => import('features/home'));
const Auth = lazy(() => import('features/auth'));
const Users = lazy(() => import('features/users'));
const Exercises = lazy(() => import('features/exercises'));
const Staff = lazy(() => import('features/staff'));
const Inbox = lazy(() => import('features/inbox'));
const Metrics = lazy(() => import('features/metrics'));
const Clinic = lazy(() => import('features/clinic'));
const EducationalContents = lazy(() => import('features/educational-contents'));
const Tasks = lazy(() => import('features/tasks'));
const ExerciseProtocols = lazy(() => import('features/exercise-protocols'));
const Forms = lazy(() => import('features/forms'));
const Assets = lazy(() => import('features/assets'));
const CarePlans = lazy(() => import('features/care-plans'));
const FunctionalAssessments = lazy(() => import('features/functional-assessments'));
const RomAssessments = lazy(() => import('features/rom-assessments'));
const StsAssessments = lazy(() => import('features/sts-assessments'));

const Embeddables = lazy(() => import('features/embeddables'));


export const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to="auth/login" />
  },
  {
    path: "/auth/*",
    element: <Auth />,
  },
  {
    path: "/embed/*",
    element: <>Loading</>
  },
  {
    path: "*",
    element: <Navigate to="/" />
  }
];


export const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/exercises/*",
        element: <Exercises />,
      },
      {
        path: "/users/*",
        element: <Users />,
      },
      {
        path: "/staff-members/*",
        element: <Staff />,
      },
      {
        path: "/inbox/*",
        element: <Inbox />,
      },
      {
        path: "/library/*",
        element: null,
      },
      {
        path: "/educational-contents/*",
        element: <EducationalContents />,
      },
      {
        path: "/tasks/*",
        element: <Tasks />,
      },
      {
        path: "/exercise-protocols/*",
        element: <ExerciseProtocols />,
      },
      {
        path: "/assets/*",
        element: <Assets />,
      },
      {
        path: "/forms/*",
        element: <Forms />,
      },
      {
        path: "/metrics/*",
        element: <Metrics />,
      },
      {
        path: "/clinic/*",
        element: <Clinic />,
      },
      {
        path: "/care-plans/*",
        element: <CarePlans />,
      },
      {
        path: "/functional-assessments/*",
        element: <FunctionalAssessments />,
      },
      {
        path: "/rom-assessments/*",
        element: <RomAssessments />
      },
      {
        path: "/sts-assessments/*",
        element: <StsAssessments />
      }
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />
  }
];


export const embedRoutes: RouteObject[] = [
  {
    path: "*",
    element: <Embeddables />
  },
];

const Router = () => {
  const protectedElement = useRoutes(protectedRoutes) as JSX.Element;
	const publicElement = useRoutes(publicRoutes) as JSX.Element;
  const embedElement = useRoutes(embedRoutes) as JSX.Element;

  const intl = useIntl();

  if (IS_EMBEDDABLE) {
    return (
      <ClinicProvider>
        <KemtaiProvider
          fetchToken={fetchEmbeddablesToken}
        >
          <WebSocketProvider>
            {embedElement}
          </WebSocketProvider>
        </KemtaiProvider>
      </ClinicProvider>
    );
  } else if (IS_LEGACY_EMBEDDABLE) {
    return (
      <ClinicProvider>
        <KemtaiProvider
          fetchToken={fetchEmbeddablesToken}
        >
          <WebSocketProvider>
            {embedElement}
          </WebSocketProvider>
        </KemtaiProvider>
      </ClinicProvider>
    );
  } else {
    return (
      <AuthLoader
        renderLoading={() => <Loader fullscreen title={intl.formatMessage({ defaultMessage: "Authenticating", id: 'dGAh2h' })} />}
        renderUnauthenticated={() => publicElement}
      >
        <ClinicProvider>
          <KemtaiProvider
            fetchToken={fetchEmbeddablesToken}
          >
            <WebSocketProvider>
              {protectedElement}
            </WebSocketProvider>
          </KemtaiProvider>
        </ClinicProvider>
      </AuthLoader>
    );
  }
  
};

export default Router;
