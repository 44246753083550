import { Embeddable } from "@kemtai/web-sdk";


export type FunctionalAssessmentResultOptions = {
  resultId: string;
};


export class FunctionalAssessmentResult extends Embeddable<FunctionalAssessmentResultOptions> {

  runType = "care";
  
  name = "functional-assessment-result";

  getDefaultLocalAppearance() {
    return {
      colors: {
        primary100: "#9A7BF0",
        primary200: "#6D53CC",
      }
    };
  }

  handleEmbeddableMessage = (message: MessageEvent) => {
    
  }

}
