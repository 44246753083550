import React from 'react';
import Router from './Router';


type LinkProps = {
  to: string,
  children?: any
}

class Link extends React.Component<LinkProps> {

  render() {

    return (
      <div onClick={()=>{Router.navigate(this.props.to)}}>
        {this.props.children}
      </div>
    );

  }

}

export default Link;
