import axios from 'axios';
import { RefreshTokenResponse } from '../types';
import { API_URL } from 'config';


export const refreshToken = async (refreshToken: string): Promise<RefreshTokenResponse> => {
  const response = await axios.post(`${API_URL}/auth/refresh-token/`, {
    refresh: refreshToken
  });

  return response.data;
};
