import { axios } from 'libs/axios';
import { IUser } from '../types';
import { LoginCredentialsDTO } from './login';


export type RegisterCredentialsDTO = LoginCredentialsDTO & {
  token: string;
  first_name: string;
  last_name: string;
};

export const registerWithEmailAndPassword = (data: RegisterCredentialsDTO): Promise<IUser> => {
  return axios.post('auth/signup/', data);
};
