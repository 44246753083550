import { axios } from 'libs/axios';
import { UserResponse } from '../types';
import { PROJECT_KEY, ENVIRONMENT_KEY } from 'config';


export type LoginCredentialsDTO = {
  email: string;
  password: string;
};

export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<UserResponse> => {
  return axios.post(`auth/login/`, {
    project_id: PROJECT_KEY,
    environment_id: ENVIRONMENT_KEY,
    ...data
  });
};
