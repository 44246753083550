import React from 'react';
import { PersonalInfoSettingsForm } from '../PersonalInfoSettingsForm';
import { LanguageSettingsForm } from '../LanguageSettingsForm';
import { PasswordSettingsForm } from '../PasswordSettingsForm';
import { useUser } from 'libs/auth';


type SettingsFormProps = {

};

export const SettingsForm: React.FC<SettingsFormProps> = () => {

  return (
    <>
      <PersonalInfoSettingsForm />

      <LanguageSettingsForm />
    </>
  );

};