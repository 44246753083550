import { Embeddable } from "@kemtai/web-sdk";


export type ExerciseProtocolResultOptions = {
  resultId: string;
};


export class ExerciseProtocolResult extends Embeddable<ExerciseProtocolResultOptions> {

  runType = "care";
  
  name = "exercise-protocol-result";

  handleEmbeddableMessage = (message: MessageEvent) => {
    
  }

}
