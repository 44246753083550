import { AuthHandler } from "./auth";
import { Workout } from "./embeddables/workout";
import { WorkoutResult } from "./embeddables/workout-result";
import { ROMAssessment } from "./embeddables/rom-assessment";
import { AppearanceOptions, KemtaiInstanceOptions, Locale } from "./types";
import { EmbeddableShape } from "./embeddables";


export class EmbeddablesManager {
 
  authHandler: AuthHandler;
  environment: "production"|"sandbox";
  appearance?: AppearanceOptions;
  locale: Locale;
  mountedEmbeddables: EmbeddableShape[] = [];
  
  constructor({ fetchToken, environment = "production", appearance, locale = "en-US" }: KemtaiInstanceOptions) {
    this.authHandler = new AuthHandler({
      fetchToken,
      onToken: this.#onToken,
    });

    this.environment = environment;
    this.appearance = appearance;
    this.locale = locale;
  }

  setAppearance = (appearance: AppearanceOptions) => {
    this.appearance = appearance;

    this.mountedEmbeddables.forEach(embeddable => {
      embeddable.setAppearance();
    });
  }

  setLocale = (locale: Locale) => {
    this.locale = locale;

    this.mountedEmbeddables.forEach(embeddable => {
      embeddable.setLocale();
    });
  }

  #onToken = (token: string) => {
    this.mountedEmbeddables.forEach(embeddable => {
      embeddable.updateAuth({
        token,
      });
    });
  }

  #terminateSession = async () => {

  }

  #updateSessionState = async () => {
    
  }
  
}

export class Kemtai {

  #manager: EmbeddablesManager;

  constructor(options: KemtaiInstanceOptions) {
    this.#manager = new EmbeddablesManager(options);
  }

  static embeddables = {
    "workout": Workout,
    "rom-assessment": ROMAssessment,
    "workout-result": WorkoutResult,
  };

  updateAppearance = (appearance: AppearanceOptions) => {
    this.#manager.setAppearance(appearance);
  }

  updateLocale = (locale: Locale) => {
    this.#manager.setLocale(locale);
  }

  createEmbeddable = <
    K extends keyof typeof Kemtai.embeddables,
    T extends InstanceType<typeof Kemtai.embeddables[K]>["options"]
  >(
    name: K,
    { 
      appearance, 
      ...options 
    }: T & { appearance?: AppearanceOptions }
  ) => {
    const EmbeddableClass = Kemtai.embeddables[name];

    const embeddable = new EmbeddableClass({
      manager: this.#manager,
      options: options as any,
      appearance,
    });

    this.#manager.mountedEmbeddables.push(embeddable);

    return embeddable as InstanceType<typeof Kemtai.embeddables[K]>;
  }
}

type Embeddables = typeof Kemtai.embeddables;

export type EmbeddableOptionsMap = {
  [K in keyof Embeddables]: InstanceType<Embeddables[K]>['options'];
};
