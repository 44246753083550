import { IClinic } from '../types';
import { axios } from 'libs/axios';
import { useQuery, QueryConfig } from 'libs/react-query';


export const getClinics = (): Promise<IClinic[]> => {
  return axios.get('care/clinics/');
};

type UseClinicsOptions = {
  config?: QueryConfig<typeof getClinics>;
};

export const useClinics = ({ config }: UseClinicsOptions = {}) => {
  return useQuery<IClinic[]>({
    queryKey: ['clinics'],
    queryFn: () => getClinics(),
    ...config,
  });
};
