import { Embeddable } from '@kemtai/web-sdk';
import { Kemtai } from '../kemtai';


export type UserCarePlanOptions = {
  userId: string;
};

export type UserCarePlanEvents = {

};

export class UserCarePlan extends Embeddable<UserCarePlanOptions, UserCarePlanEvents> {

  runType = "care";

  name = "user-care-plan";
  declare kemtai: Kemtai;

  handleEmbeddableMessage = (message: MessageEvent) => {

  }

  get embedURL() {
    if (window.__ENV__?.MANAGE_EMBED_URL) {
      return window.__ENV__.MANAGE_EMBED_URL;
    }

    if (this.manager.environment === "production") {
      return "https://care-manage.kemtai.com";
    } else {
      return "https://care-manage.sandbox.kemtai.com";
    }
  }

  get iframeSrc() {
    return `${this.embedURL}/embeddables/users/${this.options.userId}/activities/`;
  }

}
