import { Embeddable } from '@kemtai/web-sdk';


export type EducationalContentOptions = {
  educationalContentId: string;
};

export type EducationalContentEvents = {
  close: undefined;
  read: undefined;
};

export class EducationalContent extends Embeddable<EducationalContentOptions, EducationalContentEvents> {

  runType = "care";

  name = "educational-content";

  handleEmbeddableMessage = (message: MessageEvent) => {
    if (message.data.type === "close") {
      this.emit("close", undefined);
    } else if (message.data.type === "read") {
      this.emit("read", undefined);
    }
  }

}
