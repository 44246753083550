import { Embeddable } from '@kemtai/web-sdk';


export type ROMAssessmentOptions = {
  romAssessmentId: string;
};

export type ROMAssessmentEvents = {
  close: undefined;
  result: { angle: number };
};

export class ROMAssessment extends Embeddable<ROMAssessmentOptions, ROMAssessmentEvents> {

  runType = "care";

  name = "rom-assessment";

  getDefaultLocalAppearance() {
    return {
      colors: {
        background: "#F2F5F7",
        paper: "#fff",
        primary100: "#fcbf5e",
        primary200: "#FCB036",
        primary300: "#FF8A00",
      }
    };
  }
  
  handleEmbeddableMessage = (message: MessageEvent) => {
    if (message.data.type === "close") {
      this.emit("close", undefined);
    } else if (message.data.type === "result") {
      this.emit("result", { angle: message.data.angle });
    }
  }
  
}
