import { Storage } from "./types";

export let storageProxy: Storage|null = null;

export const applyStorage = (storage: Storage) => {
  storageProxy = storage;
};

export const getAccessToken = () => {
  return storageProxy?.getAccessToken();
}

export const getRefreshToken = () => {
  return storageProxy?.getRefreshToken();
}

export const setAccessToken = (token: string) => {
  return storageProxy?.setAccessToken(token);
}

export const setRefreshToken = (token: string) => {
  return storageProxy?.setRefreshToken(token);
}

export const clearAccessToken = () => {
  return storageProxy?.clearAccessToken();
}

export const clearRefreshToken = () => {
  return storageProxy?.clearRefreshToken();
}
