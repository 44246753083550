import { Grid, Typography, Menu, MenuItem, Avatar, IconButton, Box } from '@mui/material';
import { Button } from '@kemtai/ui';
import { useAnchorElement, useDialog } from '@kemtai/hooks';
import { IUser } from 'features/auth';
import { useUser, useLogout } from 'libs/auth';
import { UserAvatar } from 'components/UserAvatar';
import { SettingsDialog } from 'features/settings';
import { useCurrentStaff } from 'features/staff';
import { AboutUsDialog, HelpDialog, savebarHeight } from 'components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getPatientDisplayName } from 'features/users';
import { FormattedMessage } from 'react-intl';


export const UserMenu = () => {

  const user = useUser();
  const logout = useLogout();
  const { data: currentStaff } = useCurrentStaff();

  const [anchorEl, onClick, handleClose] = useAnchorElement<HTMLButtonElement>();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      px={1.5}
    >
      <UserAvatar
        label={user.data?.first_name?.slice(0,1)}
        picture={user.data?.picture}
        size={26}
      />

      <Typography
        variant="body3"
        noWrap
        lineHeight={1}
      >
        {user.data?.first_name && user.data.last_name && `${user.data.first_name} ${user.data.last_name}`}
        
        <Typography
          variant="caption2"
          noWrap
          component="div"
          data-testid="user-email"
        >
          {user.data?.email}
        </Typography>
      </Typography>
      
      <IconButton
        onClick={onClick}
      >
        <MoreVertIcon
          sx={{
            color: "text.300",
            fontSize: "16px"
          }}
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onBlur={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          "& .MuiPaper-root": {
            minWidth: 180,
          }
        }}
      >
        {currentStaff &&
          <SettingsDialog
            staff={currentStaff}
            triggerButton={
              <MenuItem
                data-testid="settings-button"
              >
                <FormattedMessage 
                  defaultMessage="Account settings" id="Af5zIY"
                />
              </MenuItem>
            }
          />
        }
        
        <HelpDialog 
          triggerElement={
            <MenuItem
              data-testid="help-button"
            >
              <FormattedMessage 
                defaultMessage="Help & Support" id="Uf3+S6"
              />
            </MenuItem>
          }
        />
        
        <MenuItem
          onClick={() => logout.mutate({})}
          data-testid="logout-button"
        >
          <FormattedMessage 
            defaultMessage="Logout" id="C81/uG"
          />
        </MenuItem>
      </Menu>
    </Box>
  );

}
