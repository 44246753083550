import { Box, ButtonProps, Portal } from '@mui/material';
import React, { useMemo } from 'react';
import { Button } from '@kemtai/ui';
import { useAppLayout } from 'hooks';
import { FormState } from "react-hook-form";
import { savebarHeight } from 'components';
import { ConfirmButton as ConfirmButtonComponent, ConfirmButtonTransitionState } from './confirm-button';
import { FormattedMessage, useIntl } from 'react-intl';


type SavebarProps = {
  children: React.ReactNode;
};

const SavebarRoot = ({ children }: SavebarProps) => {
	const { footerRef } = useAppLayout();

	return (
		<Portal 
			container={footerRef.current}
		>
			<Box
				display="flex"
				alignItems="center"
				gap={2}
				bgcolor="common.white"
				px={2}
				height={savebarHeight}
				sx={{
					borderTopLeftRadius: "12px",
					borderTopRightRadius: "12px",
					boxShadow: '0 -24px 20px -20px rgba(0, 0, 0, 0.15)',
				}}
			>
        {children}
			</Box>
		</Portal>
	);

};

const Spacer = () => <Box flex={1} />;

const ConfirmButton = ({
  formState: { isSubmitSuccessful, isSubmitting, isSubmitted, isValid, isDirty },
	disabled,
	label,
	disableWhenClean = true,
  ...props
}: {
  formState: FormState<any>;
	label?: string;
	disableWhenClean?: boolean;
} & ButtonProps) => {

	const transitionState = useMemo<ConfirmButtonTransitionState>(() => {
		if (isSubmitting) {
			return "loading";
		} else if (isSubmitted && !isValid) {
			return "error";
		} else if (isSubmitSuccessful) {
			return "success";
		}

		return "default";
	}, [isSubmitSuccessful, isSubmitting, isSubmitted, isValid]);

	const intl = useIntl();

	return (
		<ConfirmButtonComponent
			variant="contained"
			color="primary"
			transitionState={transitionState}
			data-test-id="button-bar-confirm"
			label={label || intl.formatMessage({ defaultMessage: "Save", id: 'jvo0vs' })}
			disabled={disabled || (disableWhenClean && !isDirty)}
			type="submit"
			{...props}
		/>
	);

}

const CancelButton = ({
  children,
  ...props
}: { children?: React.ReactNode } & ButtonProps) => (
  <Button 
		variant="outlined" 
		data-test-id="button-bar-cancel" 
		{...props}
	>
    {children || 
			<FormattedMessage 
				defaultMessage="Back" id="cyR7Kh"
			/>
		}
  </Button>
);

const DeleteButton = ({
  children,
  ...props
}: { children?: React.ReactNode } & ButtonProps) => (
  <Button 
		variant="contained"
		color="error" 
		data-test-id="button-bar-delete" 
		{...props}
	>
    {children || 
			<FormattedMessage 
				defaultMessage="Delete" id="K3r6DQ"
			/>
		}
  </Button>
);

export const Savebar = Object.assign(SavebarRoot, {
  Spacer,
  DeleteButton,
  ConfirmButton,
  CancelButton,
});