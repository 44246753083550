import { axios } from 'libs/axios';

import { IPolicyRule } from '@kemtai/auth';
import { PROJECT_KEY, ENVIRONMENT_KEY } from 'config';
import { storage } from 'libs/storage';


export const getPolicy = (): Promise<IPolicyRule[]> => {
	const tenantKey = storage.getItem("tenantKey");

	return axios.get(`/auth/policy/`, {
		params: {
			project_id: PROJECT_KEY,
			environment_id: ENVIRONMENT_KEY,
			tenant_id: tenantKey
		}
	});
};
