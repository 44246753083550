import { Control, Controller, FieldPath, FieldValues, InputValidationRules, UseControllerProps } from 'react-hook-form';
import { TextFieldProps, TextField } from '@mui/material';


export type FormInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & TextFieldProps;

export const FormInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ name, control, rules, type, disabled, ...props }: FormInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <TextField
          helperText={error?.message ?? props.helperText}
          size="small"
          error={!!error}
          onChange={(event) => {
            const { value } = event.target;

            if (type === "number") {
              if (/^\d*[.]?\d*$/.test(value)) {
                onChange(value);
              } else if (!value) {
                onChange(null);
              }
            } else {
              onChange(value || null);
            }
          }}
          value={value}
          {...props}
        />
      )}
    />
  );
};