import { FetchTokenFunction, Kemtai } from "@kemtai/web-care-sdk";
import { AppearanceOptions, Locale } from "@kemtai/web-sdk";
import React, { useEffect, useRef, useState } from "react";


type KemtaiContextValue = {
  kemtaiInstance: Kemtai;
};

export const KemtaiContext = React.createContext<KemtaiContextValue>({} as KemtaiContextValue);

type KemtaiProviderProps = {
  fetchToken: FetchTokenFunction;
  environment?: "production"|"sandbox";
  appearance?: AppearanceOptions;
  locale?: Locale;
};

export const KemtaiProvider: React.FC<KemtaiProviderProps> = ({ fetchToken, environment, appearance, locale, children }) => {
  
  const kemtaiRef = useRef<Kemtai|null>(null);

  if (kemtaiRef.current === null) {
    kemtaiRef.current = new Kemtai({
      fetchToken,
      environment,
      appearance,
      locale,
    });
  }

  useEffect(() => {
    if (appearance) {
      kemtaiRef.current!.updateAppearance(appearance);
    }
  }, [appearance]);

  useEffect(() => {
    if (locale) {
      kemtaiRef.current!.updateLocale(locale);
    }
  }, [locale]);

  return (
    <KemtaiContext.Provider 
      value={{ 
        kemtaiInstance: kemtaiRef.current!
      }}
    >
      {children}
    </KemtaiContext.Provider>
  );

}