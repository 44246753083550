import { VERSION } from "config";

export class VersionChecker {
  private versionEndpoint: string = '/version.json';
  private localVersion: string | undefined = VERSION;
  private checkInterval: number = 20 * 1000; // Check every 20 seconds
  private intervalId: NodeJS.Timeout | null = null;

  async checkForUpdate() {
    try {
      const response = await fetch(this.versionEndpoint, { cache: 'no-store' });
      const data = await response.json();
      if (data.version !== this.localVersion) {
        this.triggerUpdate();
      }
    } catch (err) {
      console.error('Error checking for app version:', err);
    }
  }

  startChecking() {
    if (!this.intervalId) {
      this.checkForUpdate();
      this.intervalId = setInterval(() => this.checkForUpdate(), this.checkInterval);
    }
  }

  stopChecking() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  triggerUpdate() {
    console.log('New version detected. Reloading the page...');
    window.location.reload();
  }
}