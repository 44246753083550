
import gzip from '@kemtai/gzip';
import { GPU } from '@kemtai/utils';

function w(k: string): any {

  if (typeof window === "undefined") {
    return undefined
  }
  return (window as any)[k]
}

const RETRY_SLEEP_START = 5000;
const RETRY_SLEEP_MULT = 5;
const RETRY_SLEEP_NUM = 5;

const isNode = typeof window === "undefined";
const origURL = isNode ? "" : window.location.href
const sessionStated = w("__started__") ?? Date.now();

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function randomString() {
  return 'xxxxxxxxxxxxxx'.replace(/[x]/g, function () {
    const r = Math.random() * 16 | 0;
    return r.toString(16);
  });
};

function getUserId(): string {
  if (isNode) {
    return ""
  }

  let userId = window.localStorage.getItem('UserId');
  if (!userId) {
    userId = randomString();
    window.localStorage.setItem('UserId', userId);
  }

  return userId;
};

async function post(url: string, data: any, keepalive: boolean = false) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Content-Encoding': 'gzip'
    },
    body: await gzip(JSON.stringify(data)),

    keepalive: keepalive
  })
}

/*
function GPU() {
  let canvas3d : OffscreenCanvas|HTMLCanvasElement
  if (typeof OffscreenCanvas !== "undefined") {
       canvas3d = new OffscreenCanvas(10,10)
  } else {
      canvas3d = document.createElement('canvas');      
  }
  try {
      let webglCtx = canvas3d.getContext("webgl")
      if (webglCtx) {
          const debugInfo = (webglCtx as any).getExtension("WEBGL_debug_renderer_info")
          if  (debugInfo) {
              return (webglCtx as any).getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
          }
      }
  } catch (e) {
  } finally {
      if (canvas3d instanceof HTMLCanvasElement) {
        console.log("remove webcontext")
        canvas3d.remove()
      }
  }
  return null
}
*/

function SourceEventData() {
  if (isNode) {
    return {}
  }

  return {
    referrer: document?.referrer,
    url: origURL,
    user_agent: navigator?.userAgent,
    gpu: GPU(),
    screenSize: `${window?.screen?.height}x${window?.screen?.width}`
  }
}

class LoggingService {
  isDisabled: boolean = false;
  data: any[] = [];
  context: { [key: string]: string } = {}
  url: string | null = null
  version: string = "";
  app: string = "";
  sessionId: string = w("__session") ?? randomString();
  userId: string = getUserId();
  messageId = 0

  constructor() {

    if (typeof self !== 'undefined') {
      (self as any).kemtaiLogger = { /* eslint-disable-line no-restricted-globals */
        backend_log: this.backendLog,
        event: this.event
      }
    }
    this.logSender();
  }

  getConfig() {
    return {
      app: this.app,
      version: this.version,
      logServerURL: this.url,
      sessionId: this.sessionId,
      userId: this.userId,
    }
  }

  setConfig(config: any) {
    if (config.sessionId) {
      this.sessionId = config.sessionId
    }
    if (config.userId) {
      this.userId = config.userId
    }
    if (config.version) {
      this.version = config.version
    }
    if (config.app) {
      this.app = config.app
    }
    if (config.url) {
      this.url = config.url
    }
  }

  init(config: any) {
    this.setConfig(config)
    //console.log("LOGGER init:", this.sessionId, this.app, this.version)
    this.event("on-logger-init", SourceEventData())
  }

  setContext(key: string, value: string | null) {
    if (value) {
      this.context[key] = value
    } else {
      delete this.context[key]
    }
  }

  clearContext(key: string) {
    delete this.context[key]
  }

  enable = () => {
    this.isDisabled = false;
  }

  disable = () => {
    this.isDisabled = true;
  }

  retryLog = async (data: any) => {
    if (!this.url) {
      return
    }
    let sleep_time = RETRY_SLEEP_START;
    for (let r = 1; r <= RETRY_SLEEP_NUM; r++) {
      console.log("logging retry", r);
      await sleep(sleep_time + Math.random() * 1000);

      try {
        data.retry = r;
        await post(this.url, data);
        return;
      } catch (error) {
        console.warn(`logging retry # ${r} failed`, error, data);
      }

      sleep_time *= RETRY_SLEEP_MULT
    }

    //s3logger.log(data);
  }

  sendData = async (keepalive: boolean = false) => {
    if (!this.url) {
      return
    }
    if (!this.data.length) {
      return;
    }

    let theData = this.data;
    this.data = []
    //console.log("sending",theData.length,"messages")
    try {
      await post(this.url, theData, keepalive);
    } catch (error) {
      console.warn("loging failed... will retry", theData);
      await this.retryLog(theData);
    }
  }

  flush() {
    this.sendData(true)
  }

  logSender = async () => {
    //console.log("LOGGER logSender", this.sessionId)

    while (true) {
      await sleep(3000);
      if (this.data.length && this.url) {
        this.sendData()
      }
    }
  }

  sessionTime = () => {
    return (Date.now() - sessionStated) / 1000.;
  }

  backendLog = (data: any) => {
    const message = { ... this.context, ...data }
    try {
      const s = JSON.stringify(data)
    } catch {
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!! LOGGING UNSERIALIBLE DATA", message)
      return
    }


    if (this.isDisabled) {
      //console.log("backend_log:loggingDisabled:", message);
      return;
    }

    message.__session = this.sessionId;
    //message.__workoutId = getWorkoutId();
    message.__userId = this.userId;
    message.__messageId = this.messageId++;
    message.sessionTime = this.sessionTime();
    message.version = this.version;
    message.app = this.app;

    this.data.push(message);
  }

  event = (name: string, metadata: any = {}) => {
    this.backendLog({ event: name, level: "user", ...metadata });
  }

  errorEvent = (name: string, metadata: any = {}) => {
    this.backendLog({ event: name, level: "error", ...metadata });
  }

  logEvent = (name: string, metadata: any = {}) => {
    this.backendLog({ event: name, level: "log", ...metadata });
  }

  logicEvent = (message: string, metadata: any = {}) => {
    this.backendLog({ event: "logiclog", level: "log", message, ...metadata });
  }

  log = (...msgs: any[]) => {
    let data: any = { level: "log" };

    for (let msg of msgs) {
      if (msg instanceof Object) {
        data = { ...data, ...msg };
      } else {
        if (data.message) {
          data.message = data.message + " " + msg;
        } else {
          data.message = msg;
        }
      }
    }

    this.backendLog(data);
  }



}

const logger = new LoggingService();

export function storeConsoleLog(...args: any[]) {
  const message = args.map((x: any) => `${x}`).join(" ");
  logger.backendLog({ level: "console-log", event: "console-log", message });
}

export default logger;

if (typeof window !== "undefined") {


  addEventListener("error", (event) => {

    logger.backendLog({
      level: "error",
      event: "window-error",
      errormessage: event.message,
      filename: event.filename,
      lineno: event.lineno,
      //stack  : event.error?.stack
    })

  });

  addEventListener("unhandledrejection", (event) => {
    //console.log("unhandledrejection:",event)
    logger.backendLog({
      level: "error",
      event: "unhandled-rejection",
      errormessage: event.reason.message,
      //stack:event.reason.stack
    })
  });

  window.addEventListener("unload", () => {
    logger.event("on-unload")
    logger.flush()
  });

}

